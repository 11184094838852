import UserLogo from '../component/UserLogo';
import { Footer } from '../component/Footer';
import RoundAvtar from '../assets/face_avtar.svg';
import ArrowImg from '../assets/arrow_next_white.svg';
import {
  ArrowIcon,
  Button,
  FaceAvtarContainer,
  FaceAvtarRound,
  Heading1,
  WelcomeConatiner,
  WelcomeHeader,
} from './styles';
import { Paragraph } from '../component/Paragraph';

const FirstStep: React.FC<{
  brandId: string;
  isLoading: boolean;
  openWebCamera: () => void;
}> = ({ brandId, openWebCamera, isLoading }) => {
  return (
    <WelcomeConatiner>
      <WelcomeHeader>
        <UserLogo brandId={brandId} />
        <Heading1>Capture of your face</Heading1>
        <Paragraph>
          We need a quick 'capture of your face'. Please follow the instructions
          and then you can look forward to receiving your video.
        </Paragraph>
      </WelcomeHeader>
      <FaceAvtarContainer>
        <FaceAvtarRound src={RoundAvtar} />
      </FaceAvtarContainer>
      <Button disabled={isLoading} onClick={openWebCamera}>
        {isLoading ? 'Loading...' : 'Get Started'}
        {!isLoading && <ArrowIcon src={ArrowImg} />}
      </Button>
    </WelcomeConatiner>
  );
};

export default FirstStep;
