import React, { useState } from 'react';
import ColorPicker from '../../components/ColorPicker';
import LogoUpload from '../../components/LogoUpload';
import { WhiteBox } from '../../components/WhiteBox';
import useSubmitLogo from './useSubmitLogo';
import { Loader } from '../../../component/Loader';
import { isProdEnv } from '../../../config/constants';
import {
  CodeCopyButton,
  ColorModalBox,
  ColorPickerBox,
  ColorShow,
  FlexCol,
  Heading,
  IframeCode,
  IframeCol,
  LogoUploadSection,
  Paragraph,
  SelectedColor,
  UpdateButton,
} from './styles';
import { FormSubmissionError } from '../../../component/common/atoms';

const ProfileSetup = ({
  files,
  setFiles,
  logoSizeError,
  setLogoSizeError,
  setClientDetail,
  setLoader,
  clientDetail,
  getDetails,
  loader,
  bgColor,
  showColorModal,
  setShowColorModal,
  setBgColor,
  hide,
}) => {
  const [copyCodeText, setCopyCodeText] = useState('Copy code');
  const [textCopiedStyles, settextCopiedStyles] = useState('');
  const [uploading, setUploading] = useState(false);
  const copyToClipboard = () => {
    navigator?.clipboard?.writeText(iframCode);
    setCopyCodeText('Code copied!');
    settextCopiedStyles('text-copied');
    setTimeout(() => {
      setCopyCodeText('Copy code');
      settextCopiedStyles('');
    }, 2000);
  };

  const fileType = files.type;
  const userId = JSON.parse(localStorage.getItem('userLogin'));
  const { saveLogo, error } = useSubmitLogo();
  const [logoUploaded, setLogoUploaded] = useState('');

  const submitLogo = async () => {
    setLoader(true);
    setLogoUploaded('');
    const params = {
      type: fileType,
      data: files,
      id: userId.brand_id,
      fileName: files.name,
      location: 'logo',
    };
    const color = bgColor?.background || bgColor;
    await saveLogo(params, color);
    getDetails();
    setLogoUploaded('Logo Uploaded Successfuly!');
    setLoader(false);
    setUploading(false);
  };

  const iframCode = `<iframe src=${'"'}${
    isProdEnv
      ? 'https://chose2-react-prod.herokuapp.com'
      : 'https://chose2-react.herokuapp.com'
  }/${clientDetail?.uuid}${'"'} style="height:700px; width:100%"></iframe>`;

  return (
    <>
      {loader && <Loader />}
      <WhiteBox>
        {!hide && (
          <>
            <Heading>Brand colour HEX</Heading>
            <ColorModalBox>
              <Paragraph>This will be used for CTA buttons</Paragraph>
              <ColorPickerBox>
                <ColorShow
                  background={bgColor.background ? bgColor.background : bgColor}
                  onClick={setShowColorModal}
                ></ColorShow>
                <SelectedColor>
                  {bgColor.background ? bgColor.background : bgColor}
                </SelectedColor>
              </ColorPickerBox>
              {showColorModal && (
                <ColorPicker
                  setShowColorModal={setShowColorModal}
                  bgColor={bgColor}
                  setBgColor={setBgColor}
                />
              )}
            </ColorModalBox>
          </>
        )}
        <LogoUploadSection>
          {error && <FormSubmissionError>{error}</FormSubmissionError>}
          <Heading>Your logo</Heading>
          <LogoUpload
            files={files}
            setFiles={setFiles}
            logoSizeError={logoSizeError}
            setLogoSizeError={setLogoSizeError}
            setClientDetail={setClientDetail}
            setUploading={setUploading}
          />
        </LogoUploadSection>
        <UpdateButton
          disabled={!uploading || logoSizeError}
          onClick={submitLogo}
        >
          Update Logo
        </UpdateButton>
      </WhiteBox>

      <WhiteBox>
        <Heading>iFrame code embed</Heading>
        <ColorModalBox>
          <Paragraph>Copy this code and paste into your application</Paragraph>
        </ColorModalBox>
        <IframeCol>
          <FlexCol>
            <CodeCopyButton
              id="copy_btn"
              onClick={() => copyToClipboard()}
              className={textCopiedStyles}
            >
              {copyCodeText}
            </CodeCopyButton>
          </FlexCol>
          <IframeCode id="myInput">{iframCode}</IframeCode>
        </IframeCol>
      </WhiteBox>
    </>
  );
};

export default ProfileSetup;
