import styled from 'styled-components';

const Container = styled.div`
  padding: 0 70px;
  margin: 32px 0;
  height: calc(100vh - 240px);
  overflow-y: scroll;
  @media only screen and (max-width: 767px) {
    padding: 0 20px;
  }
`;

export const DashboardContainer = ({ children }) => {
  return <Container>{children}</Container>;
};
