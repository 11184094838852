/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useState } from 'react';
import { S3ParamsType, S3Upload } from '../../../recording/s3upload';

const useSubmitBrandVideo = (
  setLoader: any
): {
  isSaved: boolean;
  uploading: boolean;
  error: string;
  saveBrandVideo: (
    params: S3ParamsType,
    color: string
  ) => Promise<{ file_name: string } | undefined>;
  resetBrandVideoStates: () => void;
} => {
  const [uploading, setUploading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [error, setError] = useState('');
  const saveBrandVideo = useCallback(async (params: S3ParamsType) => {
    try {
      setUploading(true);
      const response = await S3Upload(params);
      if (response) {
        setIsSaved(true);
        setUploading(false);
        return response;
      }
    } catch (error: any) {
      setError(error?.message || 'Video submition failed.');
      setLoader(false);
    }
  }, []);
  const resetBrandVideoStates = () => {
    setUploading(false);
    setIsSaved(false);
    setError('');
  };
  return {
    isSaved,
    uploading,
    error,
    saveBrandVideo,
    resetBrandVideoStates,
  };
};
export default useSubmitBrandVideo;
