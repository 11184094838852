import styled from 'styled-components';

export const FaceRecordView = styled.video`
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
  width: 300px;
  height: 300px;
  transform: scaleX(-1);
`;

export const FaceAvtarSquare = styled.div`
  width: 300px;
  height: 300px;
  margin: 0 auto;
  position: relative;
  border-radius: 50%;
`;
