import React, { createRef, useEffect } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  color: white;
`;
export const InstructionListItem = styled.p`
  display: flex;
  align-items: center;
`;
const HeadingText = styled.p`
  font-size: 1.5rem;
`;
const VideoContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const VideoElement = styled.video`
  width: 100%;
  object-fit: cover;
`;

const videosStepper = [
  '/step9.mp4', // new step 1
  // '/step10.mp4', // new step 2
  // '/step11.mp4', // new step 3
  // '/step7.mp4', // new step 4, new step 2
  '/step8.mp4', // new step 5, new step 3

  // deleted steps
  // '/step1.mp4',
  // '/step2.mp4',
  // '/step3.mp4',
  // '/step4.mp4',
  // '/step5.mp4',
  // '/step6.mp4',
];

const TutorialVideo = ({ play, step, loopComplete, isDone }) => {
  const videoRef = createRef();

  useEffect(() => {
    if (play && videoRef?.current && !isDone) {
      videoRef.current.play();
    }
    if (!play && videoRef?.current && videoRef.current.duration) {
      console.log('videoRef.current.duration', videoRef.current.duration);
      videoRef.current.currentTime = 0;
      videoRef.current.pause();
      console.log('videoRef.current.currentTime', videoRef.current.currentTime);
    }
  }, [play, isDone]);

  useEffect(() => {
    if (videoRef.current && videoRef.current.duration) {
      videoRef.current.currentTime = videoRef.current.duration;
      videoRef.current.pause();
    }
  }, [isDone]);

  const videoEnd = (e) => {
    console.log('video ended', e);
    loopComplete({
      play: videoRef.current.play.bind(videoRef.current),
      pause: videoRef.current.pause.bind(videoRef.current),
    });
  };

  return (
    <VideoContainer>
      <VideoElement
        muted
        ref={videoRef}
        src={videosStepper[step]}
        onEnded={videoEnd}
        playsInline
      >
        Hello
      </VideoElement>
    </VideoContainer>
  );
};

export default TutorialVideo;
