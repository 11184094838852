import React from 'react';
import {
  DashboardTableHeading,
  editDroDownId,
  editDropDown,
} from '../../../utils/globalFunction';
import DropDown from '../../../component/DropDown';
import CameraImg from '../../../assets/camera_icon.svg';
import MenuIcon from '../../../assets/dots_icon.svg';
import {
  CameraIcon,
  DropdownList,
  EditColumn,
  EditIcon,
  EmptyState,
  LightText,
  StatusLabel,
  Table,
  TableColumn,
  TableThumnail,
  Tbody,
  THead,
} from './styles';

const DashboardTable = ({
  openDropdown,
  setOpenDropdown,
  modalIsOpen,
  brandVideoDetail,
}) => {
  function openEditModal(dataId, itemId) {
    if (itemId === editDroDownId.edit) {
      modalIsOpen(openDropdown);
    }
  }

  return (
    <TableColumn>
      {brandVideoDetail?.total > 0 ? (
        <Table>
          <THead>
            <tr>
              {DashboardTableHeading?.map((heading) => {
                return <th key={heading.id}>{heading.heading}</th>;
              })}
            </tr>
          </THead>
          <Tbody>
            {brandVideoDetail?.data.map((data) => {
              return (
                <tr key={data.id}>
                  <td>
                    <TableThumnail>
                      <img src={data.cover_image} alt="thumbnail" />
                      {data.title}
                    </TableThumnail>
                  </td>
                  <td>
                    <b>{data.swap_remaining}%</b>
                    <LightText>Remaining swaps</LightText>
                  </td>
                  <td
                    className={
                      data.status === 'Approved'
                        ? 'approved'
                        : data.status === 'Waiting'
                        ? 'waiting'
                        : data.status === 'Expired'
                        ? 'expired'
                        : data.status === 'Rejected'
                        ? 'rejected'
                        : ''
                    }
                  >
                    <StatusLabel>{data.status}</StatusLabel>
                  </td>
                  <td>
                    <EditColumn onClick={() => setOpenDropdown(data.id, data)}>
                      <EditIcon src={MenuIcon} />
                      {openDropdown === data.id && (
                        <DropDown setOpenDropdown={setOpenDropdown}>
                          {editDropDown.map((item) => (
                            <DropdownList
                              onClick={() => openEditModal(data.id, item.id)}
                              key={item.id}
                            >
                              <span>{item.value}</span>
                            </DropdownList>
                          ))}
                        </DropDown>
                      )}
                    </EditColumn>
                  </td>
                </tr>
              );
            })}
          </Tbody>
        </Table>
      ) : (
        <EmptyState>
          <CameraIcon src={CameraImg} />
          Kindly click on &nbsp;Add new video &nbsp;to upload a video
        </EmptyState>
      )}
    </TableColumn>
  );
};

export default DashboardTable;
