import React from 'react';
import { Heading1 } from '../styles';
import moveMessages from '../helpers/steps';

export const moveLeftFrames = [
  { yaw: -60, pitch: 0, valid: false },
  { yaw: -55, pitch: 0, valid: false },
  { yaw: -50, pitch: 0, valid: false },
  { yaw: -45, pitch: 0, valid: false },
  { yaw: -40, pitch: 0, valid: false },
  { yaw: -35, pitch: 0, valid: false },
  { yaw: -30, pitch: 0, valid: false },
  { yaw: -20, pitch: 0, valid: false },
  { yaw: -10, pitch: 0, valid: false },
  { yaw: 0, pitch: 0, valid: false },
];
export const moveRightFrames = [
  { yaw: 60, pitch: 0, valid: false },
  { yaw: 55, pitch: 0, valid: false },
  { yaw: 50, pitch: 0, valid: false },
  { yaw: 45, pitch: 0, valid: false },
  { yaw: 40, pitch: 0, valid: false },
  { yaw: 35, pitch: 0, valid: false },
  { yaw: 30, pitch: 0, valid: false },
  { yaw: 20, pitch: 0, valid: false },
  { yaw: 10, pitch: 0, valid: false },
  { yaw: 0, pitch: 0, valid: false },
];
const messages = {
  default: 'Position your face within the frame.',
  extremeLeft: 'Slowly turn your head left',
  topLeft: 'Slowly turn your head left and make circle',
};

const Messages = ({ step, startRecording }) => {
  if (!startRecording) {
    return (
      <div>
        <Heading1>{messages.default}</Heading1>
      </div>
    );
  }
  return (
    <div>
      <Heading1>{moveMessages[step]}</Heading1>
    </div>
  );
};

export default React.memo(Messages);
