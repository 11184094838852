/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react';
import apiClient from '../network/apiClient';
import { loginApi } from '../config/constants';

const useLogin = (setLoader) => {
  const [isSubmited, setIsSubmited] = useState(false);
  const [apiError, setApiError] = useState(null);

  const login = async (payload) => {
    setApiError(null);
    const postObj = {
      email: payload.email,
      first_name: payload.firstName,
      last_name: payload.lastName,
      term_and_condition: payload.termAndCondition,
      brand_video_id: payload.brandId,
    };
    try {
      const userData = await apiClient.post(loginApi, postObj);
      localStorage.setItem('user', JSON.stringify(userData.data));
      setIsSubmited(true);
    } catch (error) {
      setApiError(error.message || 'Api failed.');
      setLoader(false);
    }
  };

  return {
    isSubmited,
    setIsSubmited,
    apiError: apiError,
    login,
    setApiError,
  };
};
export default useLogin;
