/* eslint-disable @typescript-eslint/naming-convention */
import { getPresignedUrlApi } from '../config/constants';
import apiClient from '../network/apiClient';
import { MimeTypes } from './WebCameraRecorder';

export interface S3ParamsType {
  type: MimeTypes;
  data: Blob;
  id: number;
  fileName: string;
  location: string;
}

// handle S3 upload
export const getSignedUrl = async (file: S3ParamsType) => {
  const queryString = `?file=${file.fileName}&location=${file.location}`;
  return apiClient(getPresignedUrlApi + queryString);
};

export const S3Upload = async (
  fileInfo: S3ParamsType
): Promise<{ file_name: string }> => {
  try {
    const s3Info = await getSignedUrl(fileInfo);
    if (!s3Info.data.presigned_url) {
      throw new Error('Did not get signed url');
    }
    await apiClient.put(s3Info.data.presigned_url, fileInfo.data, {
      headers: {
        'Content-type': fileInfo.type,
        'x-amz-acl': 'private',
      },
    });
    return s3Info.data;
  } catch (error: any) {
    console.error(error);
    throw new Error(error?.message || 'File not uploaded!');
  }
};
