import styled from 'styled-components';
import { colors } from '../../../../theme';

export const AddVideoButton = styled.div`
  background: ${colors.white};
  padding: 9px 17px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #111827;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  align-items: center;
  & img {
    margin-right: 10px;
  }
  transition: transform 250ms;
  &:hover {
    transform: translateY(-2px);
  }
  @media only screen and (max-width: 767px) {
    margin-top: 24px;
  }
`;

export const customStyles = {
  overlay: {
    background: 'rgba(55, 65, 81, 0.8)',
    zIndex: 20,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 512,
    background: '#fff',
    boxShadow:
      '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    border: 'none',
    padding: 24,
  },
};

export const editVideoModalStyles = {
  overlay: {
    background: 'rgba(55, 65, 81, 0.8)',
    zIndex: 20,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 981,
    background: '#fff',
    boxShadow:
      '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    border: 'none',
    padding: 0,
  },
};
