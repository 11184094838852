import React from 'react';
import styles, { Container, Line } from './style';
import { ReactComponent as Arrow } from '../../../assets/arrow.svg';
import { animationClass } from '../../helpers/steps';
type Props = {
  position: string;
};

const Indication: React.FC<Props> = ({ position }) => {
  console.log('poistion', position);
  return (
    <Container style={animationClass(position, styles)}>
      <Line />
      <Arrow width={50} height={50} style={styles.arrow} />
    </Container>
  );
};

export default Indication;
