import React from 'react';
import { useClickOutside } from '../../utils/globalFunction';
import { DropDownCol, DropdownListCol } from './styles';

const DropDown = ({ children, setOpenDropdown }) => {
  const domNode = useClickOutside(() => {
    setOpenDropdown(false);
  });

  return (
    <DropDownCol ref={domNode}>
      <DropdownListCol>{children}</DropdownListCol>
    </DropDownCol>
  );
};

export default DropDown;
