import React from 'react';
import styled from 'styled-components';
import { CTAButton, WelcomeConatiner } from './styles';
import { ReactComponent as EarsIcon } from '../assets/ears.svg';
import { ReactComponent as GlassesIcon } from '../assets/glasses.svg';
import { ReactComponent as PhoneIcon } from '../assets/phone.svg';
import { ReactComponent as SunIcon } from '../assets/sun.svg';
import { colors } from '../theme';

const Container = styled.div`
  color: white;
  width: 100%;
  max-width: 640px;

  @media only screen and (max-width: 767px) {
    border-radius: 0;
  }
`;

export const InstructionListItem = styled.p`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 767px) {
    font-size: 1rem;
  }
`;

const Text = styled.span`
  margin: 0;
`;

const allStyles = {
  icon: {
    minWidth: 24,
    marginRight: 10,
  },
};

const HeadingText = styled.p`
  font-size: 1.5rem;
  margin-top: 0;

  @media only screen and (max-width: 767px) {
    font-size: 1.3rem;
  }
`;

const Instructions = styled.div`
  margin: 40px 0;
  width: 100%;

  @media only screen and (max-width: 767px) {
    margin: 24px 0;
  }
`;

const ThemeBackground = styled.div`
  background: ${colors.darkGrey};
  padding: 48px 90px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 767px) {
    padding: 12px 5%;
  }
`;

const VideoCapture = ({ nextStep }) => {
  return (
    <ThemeBackground>
      <WelcomeConatiner>
        <Container>
          <HeadingText>
            To produce your face swap video, we need to capture some quick
            selfie videos.
            <br /> <br />
            They will be used to produce your face swap video and then be
            deleted.
          </HeadingText>
          <HeadingText>For best results please make sure that...</HeadingText>
          <Instructions>
            <InstructionListItem>
              <GlassesIcon style={allStyles.icon} />
              <Text>You're not wearing any glasses or a hat</Text>
            </InstructionListItem>
            <InstructionListItem>
              <SunIcon style={allStyles.icon} />
              <Text>Your face is well lit and fully visible</Text>
            </InstructionListItem>
            <InstructionListItem>
              <EarsIcon style={allStyles.icon} />
              <Text>
                Your ears are fully visible i.e. hair behind your ears
              </Text>
            </InstructionListItem>
          </Instructions>
          <HeadingText>
            When you click start you will see a series of instruction videos
            which you need to copy
          </HeadingText>
          <CTAButton onClick={nextStep}>Start</CTAButton>
        </Container>
      </WelcomeConatiner>
    </ThemeBackground>
  );
};

export default VideoCapture;
