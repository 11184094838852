import styled from 'styled-components';
import { colors } from '../../theme';

export const ContinueButton = styled.button`
  padding: 9px 17px;
  border-radius: 20px;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background: ${colors.redColor};
  width: 100%;
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  max-width: ${(props) => props.maxWidth || '300px'};
  margin: 0 auto;
  font-family: 'Inter', sans-serif;
  transition: transform 250ms;
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};

  &:hover {
    transform: translateY(-2px);
  }
  & img {
    margin-left: 5px;
    width: 12px;
  }
`;
