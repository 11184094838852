import React, { useEffect, useRef } from 'react';

const Audio = ({ play }) => {
  const audioRef = useRef();
  useEffect(() => {
    if (play) {
      audioRef.current.play();
    }
  }, [play]);
  return (
    <div>
      <audio ref={audioRef}>
        <source src="/tune.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default React.memo(Audio);
