import React, { useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import CoverImageUpload from '../CoverImageUpload';
import closeIcon from '../../../assets/close_icon.svg';
import {
  Body,
  Button,
  CloseIcon,
  Footer,
  FormColumn,
  Header,
  Heading,
  Heading5,
  HeadingCol,
  HeadingInput,
  LeftCol,
  Paragraph,
  RightCol,
  SubTitle,
  SwapColumn,
  TitleCol,
  VideoPlayer,
} from './styles';
import { colors } from '../../../theme';
import { ValidateNumeric, ValidateTitle } from '../../../utils/globalFunction';
import userBrandVideoUpload from '../UploadNewVideoModal/useBrandVideoUpload';
import Error from '../../../component/Error';
import useSubmitCoverImage from '../UploadNewVideoModal/useSubmitCoverImage';

const EditVideoModal = ({ onRequestClose, data, setLoader }) => {
  const coverImage = 'cover_image';
  const [errors, setErrors] = useState({});
  const [formState, setFormState] = useState({
    title: { value: data?.title, edited: false, key: 'title' },
    swapAllowed: {
      value: data?.swap_allowed,
      edited: false,
      key: 'swap_allowed',
    },
    coverImage: { value: '', edited: false, key: coverImage },
    id: data?.id,
  });

  const [videoFile] = useState({
    preview: data?.video_url,
  });
  const [addCoverFile, setAddCoverFile] = useState({
    preview: data?.cover_image,
  });
  const { saveCoverImg } = useSubmitCoverImage();
  const { brandVideoUpdate, apiError } = userBrandVideoUpload(setLoader);

  const onChangeAddCoverFile = (file) => {
    setAddCoverFile(file);
    setFormState((prevState) => ({
      ...prevState,
      coverImage: file.name,
    }));
  };

  const uploadCoverImage = async () => {
    const userId = JSON.parse(localStorage.getItem('userLogin'));
    const params = {
      type: addCoverFile?.type,
      data: addCoverFile,
      id: userId.brand_id,
      fileName: addCoverFile?.name,
      location: coverImage,
    };
    return await saveCoverImg(params);
  };

  const validateForm = (shouldSetError = true) => {
    const errors = {};
    const { title, swapAllowed, coverImage } = formState;
    let error = ValidateTitle(title.value);
    if (error) {
      errors.title = 'The title ' + error;
    }
    error = ValidateNumeric(swapAllowed.value);
    if (error) {
      errors.swapAllowed = 'The swaps ' + error;
    }
    if (coverImage.length < 1) {
      errors.coverImage = true;
    }
    if (shouldSetError) setErrors(errors);
    return Object.keys(errors).length > 0;
  };

  const handleSubmit = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length) {
      return;
    }
    setLoader(true);
    const payload = {};
    for (let values in formState) {
      const formValue = formState[values];
      if (formValue.edited) {
        payload[formValue.key] = formValue.value;
      }
    }
    if (addCoverFile.name) {
      const editCoverImage = await uploadCoverImage();
      payload.cover_image = editCoverImage.file_name;
    }
    const updateResponse = await brandVideoUpdate(payload, formState?.id);
    setLoader(false);
    if (updateResponse) {
      onRequestClose();
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setFormState((prevState) => ({
      ...prevState,
      [name]: { value, edited: true, key: prevState[name].key },
    }));
    errors[name] = '';
    if (name === 'title') {
      const error = ValidateTitle(value);
      if (error) {
        errors.title = 'The title ' + error;
      }
    }
    if (name === 'swapAllowed') {
      const error = ValidateNumeric(value);
      if (error) {
        errors.swapAllowed = 'The swaps ' + error;
      }
    }
    return;
    setErrors(errors);
  };
  const isSaveDisabled = useMemo(() => validateForm(false), [formState]);
  return (
    <>
      <Header>
        <HeadingCol>
          <Heading>Edit video</Heading>
          <CloseIcon onClick={onRequestClose} src={closeIcon} />
        </HeadingCol>
        <SubTitle>Update your details about your video here</SubTitle>
      </Header>
      <Body>
        <LeftCol>
          <TitleCol>
            <SwapColumn>
              <Heading5>Title</Heading5>
            </SwapColumn>
            <FormColumn>
              <HeadingInput
                onChange={handleChange}
                name="title"
                defaultValue={formState?.title?.value}
              />
              <Error touched={true} message={errors.title} />
            </FormColumn>
          </TitleCol>
          <TitleCol>
            <SwapColumn>
              <Heading5>Cover image</Heading5>
            </SwapColumn>
            <CoverImageUpload
              selectedImage={formState?.cover_image}
              addCoverFile={addCoverFile}
              onChangeAddCoverFile={onChangeAddCoverFile}
            />
          </TitleCol>
          <TitleCol>
            <SwapColumn>
              <Heading5>Number of swaps allowed</Heading5>
              <Paragraph>
                Enter the number of swaps you want to allow for this video.
              </Paragraph>
            </SwapColumn>
            <FormColumn>
              <HeadingInput
                textAlign="right"
                width="140px"
                name="swapAllowed"
                onChange={handleChange}
                defaultValue={formState.swapAllowed?.value}
                type="number"
              />
              <Error touched={true} message={errors.swapAllowed} />
            </FormColumn>
          </TitleCol>
        </LeftCol>
        <RightCol>
          {videoFile?.preview && (
            <VideoPlayer>
              <ReactPlayer
                className="react-palyer"
                width="296px"
                height="170px"
                url={videoFile?.preview}
                playing={false}
                controls={true}
              />
            </VideoPlayer>
          )}
        </RightCol>
      </Body>
      <Footer>
        {apiError ===
        'The swap allowed must be greater than swap already done.' ? (
          <Error
            className="custom-error"
            touched={true}
            message="The number you have entered is less than you have already used for
          this video please contact support@chose2.com if you require more
          assistance."
          />
        ) : (
          <Error className="custom-error" touched={true} message={apiError} />
        )}
        <Button
          onClick={onRequestClose}
          background="transparent"
          color={colors.darkGreyText}
        >
          Cancel
        </Button>
        <Button disabled={isSaveDisabled} onClick={handleSubmit}>
          Save
        </Button>
      </Footer>
    </>
  );
};

export default EditVideoModal;
