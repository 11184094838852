import styled from 'styled-components';
import { colors, fontFamily } from '../../../theme';

export const customStyles = {
  overlay: {
    background: 'rgba(55, 65, 81, 0.8)',
    zIndex: 20,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 512,
    background: '#fff',
    boxShadow:
      '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    border: 'none',
    padding: 24,
  },
};

export const ColorModalBox = styled.div`
  position: relative;
`;

export const Heading = styled.h3`
  font-weight: ${(props) => props.fontWeight || '700'};
  font-size: ${(props) => props.fontSize || '18px'};
  line-height: ${(props) => props.lineHeight || '28px'};
  color: ${colors.darkGrey};
  margin: 0;
`;

export const Paragraph = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.subParagraph};
  margin: 8px 0 16px;
`;

export const ColorPickerBox = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.borderColor};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 8px 13px;
  display: flex;
  align-items: center;
`;

export const ColorShow = styled.span`
  width: 16px;
  height: 16px;
  background: ${(props) => props.background || '#000'};
  border-radius: 2px;
  display: flex;
`;

export const SelectedColor = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.subParagraph};
  margin: 0 0 0 8px;
`;

export const LogoUploadSection = styled.div`
  margin-bottom: 48px;
`;

export const UpdateButton = styled.button`
  background: ${colors.darkGrey};
  border: none;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.white};
  padding: 9px 13px;
  font-family: ${fontFamily.default};
  cursor: pointer;
  &:disabled {
    background: ${colors.navColor};
    color: ${colors.subParagraph};
    cursor: auto;
  }
`;

export const IframeCol = styled.div`
  border: 1px solid ${colors.borderColor};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 17px;
`;

export const CodeCopyButton = styled.div`
  background: ${colors.darkGrey};
  color: ${colors.white};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 9px 13px;
  cursor: pointer;
`;

export const IframeCode = styled.div`
  margin-top: 16px;
  color: ${colors.subParagraph};
`;

export const FlexCol = styled.div`
  display: flex;
  justify-content: flex-end;
  .text-copied {
    background: transparent;
    border: 1px solid ${colors.borderColor};
    color: ${colors.darkGreyText};
    padding: 8px 13px;
  }
`;
