import styled from 'styled-components';
import { colors } from '../theme';

export const ThemeBackground = styled.div`
  background: ${colors.darkGrey};
  padding: 48px 90px;
  @media only screen and (max-width: 767px) {
    padding: 48px 24px;
    height: auto;
  }
`;

export const WelcomeConatiner = styled.div``;

export const ArrowIcon = styled.img`
  margin-left: 6px;
  width: 14px;
`;

export const WelcomeHeader = styled.div`
  text-align: center;
`;

export const BrandLogoIcon = styled.img`
  width: ${(props) => props.width || '100px'};
  height: ${(props) => props.height || '100px'};
`;

export const Heading1 = styled.h1`
  color: ${colors.white};
  font-size: 30px;
  font-weight: 800;
  line-height: 36px;
  margin: 20px 0 8px;
`;

export const Paragraph = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.borderColor};
  max-width: 620px;
  margin: 0 auto 32px;
`;

export const WhiteBox = styled.div`
  max-width: ${(props) => props.maxWidth || '384px'};
  width: 100%;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin: ${(props) => props.margin || '16px auto'};
  padding: ${(props) => props.padding || '24px'};
  text-align: ${(props) => props.textAlign || 'center'};
  background: #1f2937;
`;

export const WhiteBoxOption = styled(WhiteBox)`
  border: 2px solid transparent;
  border-color: ${(props) =>
    props.selected ? colors.redColor : 'transparent'};
  background: ${(props) => (props.selected ? colors.red100 : colors.white)};
  cursor: pointer;
  &:hover {
    background: ${colors.red100};
    border: 2px solid ${colors.redColor};
  }
`;

export const Paragraph2 = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  opacity: 0.7;
  margin: ${(props) => props.margin || '0 0 24px'};
`;

export const Heading4 = styled.h4`
  font-weight: 500;
  line-height: 24px;
  color: ${colors.white};
  font-size: ${(props) => props.fontSize || '18px'};
  margin: ${(props) => props.margin || '20px 0 8px'};
`;
