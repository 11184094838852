import styled from 'styled-components';
import { colors } from '../../theme';

export const Paragraph = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.borderColor};
  max-width: 470px;
  margin: 0 auto;
  text-align: center;
`;
