import React from 'react';
import { settingTabs } from '../../../utils/globalFunction';
import { MenuList, NavCol } from './styles';

const Tabs = ({ selectTab, setSelectTab }) => {
  return (
    <NavCol>
      {settingTabs &&
        settingTabs.map((data, index) => {
          return (
            <MenuList key={index}>
              <p
                className={selectTab === index ? 'active' : ''}
                onClick={() => setSelectTab(index)}
              >
                {data.name}
              </p>
            </MenuList>
          );
        })}
    </NavCol>
  );
};

export default Tabs;
