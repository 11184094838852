import React from 'react';
import { ContinueButton } from '../component/ContinueButton';
import { Footer } from '../component/Footer';
import AlertIcon from '../assets/alert-icon.svg';

import {
  BrandLogoIcon,
  Heading4,
  Paragraph2,
  ThemeBackground,
  WelcomeConatiner,
  WelcomeHeader,
  WhiteBox,
} from './styles';

const VideoMessage = () => {
  return (
    <ThemeBackground>
      <WelcomeConatiner>
        <WelcomeHeader>
          <img
            alt=""
            src="/static/media/footer_logo.9ba6617351afd185cbd7e0b73c25ace4.svg"
          />
          <br /> <br /> <br />
        </WelcomeHeader>
        <WhiteBox>
          <BrandLogoIcon width="48px" height="48px" src={AlertIcon} />
          <Heading4>Face swap already completed</Heading4>
          <Paragraph2>
            You have already completed a face swap. Please check your email for
            the link.
          </Paragraph2>
          <ContinueButton onClick={() => null}>OK</ContinueButton>
        </WhiteBox>
      </WelcomeConatiner>
      <Footer />
    </ThemeBackground>
  );
};

export default VideoMessage;
