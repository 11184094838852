import styled from 'styled-components';
import { colors, fontFamily } from '../../../theme';

export const Header = styled.div`
  background: ${colors.lightGray};
  padding: 24px;
`;

export const HeadingCol = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const Heading = styled.h3`
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: ${colors.darkGrey};
  margin: 0;
`;

export const CloseIcon = styled.img`
  cursor: pointer;
`;

export const SubTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.subParagraph};
  margin: 0;
`;

export const Body = styled.div`
  padding: 0 24px 24px;
  display: flex;
  justify-content: space-between;
`;

export const LeftCol = styled.div`
  max-width: 613px;
  width: 100%;
`;

export const TitleCol = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.navColor};
  padding: 20px 0;
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

export const Heading5 = styled.h5`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.darkGrey};
  margin: 0;
`;

export const HeadingInput = styled.input`
  width: ${(props) => props.width || '397px'};
  background: white;
  border: 1px solid ${colors.inputBorder};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.darkGrey};
  font-family: ${fontFamily.default};
  padding: 8px 13px;
  text-align: ${(props) => props.textAlign || 'left'};
`;

export const SwapColumn = styled.div`
  width: 192px;
  margin-right: 24px;
`;

export const Paragraph = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.subParagraph};
`;

export const Footer = styled.div`
  border-top: 1px solid ${colors.navColor};
  justify-content: flex-end;
  display: flex;
  align-items: flex-end;
  padding: 24px;
  align-items: center;
  & .custom-error {
    flex: 1;
  }
`;

export const Button = styled.button`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background: ${(props) => props.background || colors.darkGrey};
  color: ${(props) => props.color || colors.white};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 8px 17px;
  cursor: pointer;
  border: 1px solid ${colors.inputBorder};
  font-family: ${fontFamily.default};
  &:last-child {
    margin-left: 16px;
  }
  &:disabled {
    background: ${colors.navColor};
    color: ${colors.subParagraph};
    border: 1px solid ${colors.navColor};
  }
`;

export const RightCol = styled.div`
  max-width: 296px;
  width: 100%;
  height: 170px;
  margin-top: 20px;
`;

export const VideoImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 7px;
  object-fit: cover;
  object-position: center;
`;

export const VideoPlayer = styled.div`
  video {
    object-fit: cover;
  }
`;

export const FormColumn = styled.div``;
