import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import Header from '../components/DashboardHeader';
import VideoUploadModal from '../components/VideoUploadModal';
import Tabs from '../components/DashboardTabs';
import ProfileSetup from '../components/ProfileSetup';
import { DashboardContainer } from '../components/DashboardContainer';
import { DashboardFooter } from '../components/DashboardFooter';
import { Loader } from '../../component/Loader';
import useClientDetails from './useClientDetails';
import { customStyles, DashboardBody } from './styles';

const Setting = () => {
  const [hide] = useState(true);
  const [showColorModal, setShowColorModal] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [bgColor, setBgColor] = useState('#000000');
  const [selectTab, setSelectTab] = useState(0);
  const [files, setFiles] = useState({});
  const [logoSizeError, setLogoSizeError] = useState('');
  const [loader, setLoader] = useState(false);
  const getToken = localStorage.getItem('token' || '');
  const { getDetails, clientDetail } = useClientDetails(getToken, setLoader);

  useEffect(() => {
    document.body.classList.add('white-bg');
  }, []);

  useEffect(() => {
    setLoader(true);
    getDetails();
  }, []);

  useEffect(() => {
    if (clientDetail) {
      setBgColor(clientDetail?.color);
      setFiles({ preview: clientDetail?.logo });
    }
  }, [clientDetail]);

  const handleModal = useCallback(() => {
    setIsOpen((currentValue) => !currentValue);
  }, []);

  return (
    <DashboardBody>
      {loader && <Loader />}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <VideoUploadModal onRequestClose={handleModal} />
      </Modal>
      <Header modalIsOpen={handleModal} />
      <DashboardContainer>
        <Tabs selectTab={selectTab} setSelectTab={setSelectTab} />
        <ProfileSetup
          showColorModal={showColorModal}
          setShowColorModal={setShowColorModal}
          bgColor={bgColor}
          setBgColor={setBgColor}
          files={files}
          setFiles={setFiles}
          logoSizeError={logoSizeError}
          setLogoSizeError={setLogoSizeError}
          loader={loader}
          setLoader={setLoader}
          getDetails={getDetails}
          clientDetail={clientDetail}
          hide={hide}
        />
      </DashboardContainer>
      <DashboardFooter hide={hide} />
    </DashboardBody>
  );
};

export default Setting;
