import styles from './styles';
import { defaultVideoValidations } from './index';

const VideoErrors: React.FC<{ errors: typeof defaultVideoValidations }> = ({
  errors,
}) => {
  const validationErrors = Object.values(errors).filter((error) => !!error);
  if (!validationErrors.length) {
    return <></>;
  }
  return (
    <div style={styles.videoMatricResult}>
      <p style={styles.Heading}>
        <b>Error messages</b>
      </p>
      {validationErrors.map((errorMessage, key) => (
        <p key={key} style={styles.red}>
          {errorMessage}
        </p>
      ))}
    </div>
  );
};

export default VideoErrors;
