import React, { useEffect } from 'react';

export function ValidateEmail(email) {
  return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
}

export function ValidatePassword(password) {
  if (password.length <= 0) {
    return ' field is required.';
  }
  if (password.search(/[a-z]/i) < 0) {
    return ' must contain at least one letter';
  }
  if (password.search(/[0-9]/) < 0) {
    return ' must contain at least one digit';
  }
  return '';
}

export function ValidateNumeric(numeric) {
  if (numeric?.length <= 0) {
    return ' field is required.';
  }
  if (numeric < 1) {
    return ' should be greater than 0';
  }
  return '';
}

export function ValidateTitle(title) {
  if (title.length <= 0) {
    return ' field is required.';
  }
  return '';
}

export function ValidateName(name) {
  if (name.length <= 0) {
    return ' field is required.';
  }
  // if (!/^[a-zA-Z]+$/.test(name)) {
  //   return ' should be in alphabets';
  // }
  if (name.length < 3) {
    return ' should be of minimum 3 characters';
  }
  if (name.length > 120) {
    return ' minimum characters allowed is 120';
  }
  return '';
}

export const getQueryParam = (query, value) => {
  if (!query) {
    return null;
  }
  try {
    let b = query
      .split('&')
      .find((l) => l.includes(value))
      .split('=');
    return b[b.length - 1];
  } catch (e) {
    console.error(e);
    return null;
  }
};

// Outside click state false function

export const useClickOutside = (handler) => {
  const domNode = React.createRef();
  useEffect(() => {
    const maybeHandler = (event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };
    document.addEventListener('mousedown', maybeHandler);
    return () => {
      document.removeEventListener('mousedown', maybeHandler);
    };
  });
  return domNode;
};

export const navigations = [
  {
    id: 1,
    name: 'Home',
    link: '/dashboard',
  },
  {
    id: 2,
    name: 'Settings',
    link: '/settings',
  },
];

export const DashboardTableHeading = [
  {
    id: 0,
    heading: 'Name',
  },
  {
    id: 1,
    heading: 'Swaps',
  },
  {
    id: 2,
    heading: 'Status',
  },
  {
    id: 3,
    heading: '',
  },
];

export const tabId = {
  profileSetUp: 1,
  userInformation: 2,
};

export const settingTabs = [
  {
    id: tabId.profileSetUp,
    name: 'Profile setup',
  },
];

export const editDroDownId = {
  edit: 1,
  download: 2,
};

export const editDropDown = [
  {
    id: editDroDownId.edit,
    value: 'Edit',
  },
];
