import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { chooseOptionApi } from '../api/chooseOption';
import { Loader } from '../component/Loader';
import { getQueryParam } from '../utils/globalFunction';
import SubmitVideos from './components/SubmitVideos';
import SubmitVideosError from './components/SubmitVideos/SubmitVideoError';
import VideoCaptureStep2 from './SecondStep';
import VideoCapture from './VideoCapture';

const NewRecordingFlow = ({ location }) => {
  let brandId = getQueryParam(location?.search, 'brand_video_id');
  const token = getQueryParam(location?.search, 'token');
  const userId = getQueryParam(location?.search, 'user_id');
  const history = useHistory();
  const [error, setError] = useState(false);
  const [steps, setSteps] = useState(0);

  const [existingVideos, setExistingVideos] = useState(null);
  const [loading, setLoading] = useState(true);

  async function getExistingVideos() {
    try {
      const videos = await chooseOptionApi({ token, brandId });
      if (!videos) {
        history.push('/404');
      }
      if (videos?.face_video) {
        setExistingVideos(videos);
      }
      setLoading(false);
    } catch (e) {
      console.log('error');
      history.push('/404');
      setError(true);
    }
  }
  useEffect(() => {
    getExistingVideos();
  }, []);

  if (!brandId || !token || error) {
    return <Redirect to={{ pathname: '/404', state: { brandId, token } }} />;
  }
  if (loading) {
    return <Loader />;
  }
  if (existingVideos) {
    return (
      <Redirect to={{ pathname: '/Options', state: { brandId, token } }} />
    );
  }

  if (steps === 1) {
    return (
      <VideoCaptureStep2
        brandId={brandId}
        userId={userId}
        nextStep={(step) => setSteps(step ? step : 2)}
      />
    );
  }
  if (steps === 2) {
    return <SubmitVideos />;
  }
  if (steps === 5) {
    return <SubmitVideosError />;
  }
  return <VideoCapture nextStep={() => setSteps(1)} />;
};

export default NewRecordingFlow;
