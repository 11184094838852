import styled from 'styled-components';
import { colors } from '../../../theme';

export const NavCol = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  border-bottom: 1px solid ${colors.navColor};
`;

export const MenuList = styled.li`
  margin-right: 36px;
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.subParagraph};
    text-decoration: none;
    padding: 17px 4px;
    border-bottom: 2px solid transparent;
    margin: 0;
    cursor: pointer;
    &:hover {
      color: ${colors.tabHover};
      border-bottom: 2px solid ${colors.tabHover};
    }
  }
  .active {
    color: ${colors.tabHover};
    border-bottom: 2px solid ${colors.tabHover};
  }
`;
