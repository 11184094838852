import { Emotion, EMOTIONS } from './types';

const mouthOpenPattern = /mouth+ +?([0-9]+([.][0-9]*)?|[.][0-9]+)+\% +open/gi;
const minConfidenceOfMouthOpen = 30;
const minResolutionWidth = 1920;

export const detectMouthOpen = (string: string): boolean => {
  if (!string.match(mouthOpenPattern)) {
    return false;
  }
  const percent = string.match(/([0-9]+([.][0-9]*)?|[.][0-9]+)/g)?.[0] || 0;
  return percent ? Number(percent) > minConfidenceOfMouthOpen : false;
};

export const detectMouthClose = (string: string) => {
  return !string.match(mouthOpenPattern);
};

export const detectFaceMoveToLeft = (string: string) => {
  return string === 'facing left';
};

export const detectFaceMoveToRight = (string: string) => {
  return string === 'facing right';
};

export const detectHeadUp = (string: string) => {
  return string === 'head up';
};

export const detectHeadDown = (string: string) => {
  return string === 'head down';
};

export const matchMinResolution = (width: number) => {
  return width >= minResolutionWidth;
};

export const checkMediaRecorderSupport = () => {
  return MediaRecorder.isTypeSupported('video/webm');
};

export const detectFaceGestures = (res: any[]) => {
  if (!res) return [];
  const gestures = [];
  for (let i = 0; i < res.length; i++) {
    const happyEmotion = res[i].emotion.find(
      (e: Emotion) => e.emotion === EMOTIONS.HAPPY
    );
    const angryEmotion = res[i].emotion.find(
      (e: Emotion) => e.emotion === EMOTIONS.ANGRY
    );
    const sadEmotion = res[i].emotion.find(
      (e: Emotion) => e.emotion === EMOTIONS.SAD
    );
    if (happyEmotion && happyEmotion.score > 0.5) {
      gestures.push({
        face: i,
        gesture: happyEmotion.emotion,
      });
    }
    if (angryEmotion && angryEmotion.score > 0.2) {
      gestures.push({
        face: i,
        gesture: angryEmotion.emotion,
      });
    }
    if (sadEmotion && sadEmotion.score > 0.5) {
      gestures.push({
        face: i,
        gesture: sadEmotion.emotion,
      });
    }
    if (res[i].mesh && res[i].mesh.length > 450) {
      const zDiff = (res[i].mesh[33][2] || 0) - (res[i].mesh[263][2] || 0);
      const xDiff = res[i].mesh[33][0] - res[i].mesh[263][0];
      if (Math.abs(zDiff / xDiff) <= 0.15)
        gestures.push({
          face: i,
          gesture: 'facing center' + Math.abs(zDiff / xDiff),
        });
      else if (zDiff < -45) {
        gestures.push({
          face: i,
          gesture: `facing left`,
        });
      } else if (zDiff > 40) {
        gestures.push({
          face: i,
          gesture: `facing right`,
        });
      }
      const openLeft =
        Math.abs(res[i].mesh[374][1] - res[i].mesh[386][1]) /
        Math.abs(res[i].mesh[443][1] - res[i].mesh[450][1]);
      if (openLeft < 0.2) gestures.push({ face: i, gesture: 'blink left eye' });
      const openRight =
        Math.abs(res[i].mesh[145][1] - res[i].mesh[159][1]) /
        Math.abs(res[i].mesh[223][1] - res[i].mesh[230][1]);
      if (openRight < 0.2)
        gestures.push({ face: i, gesture: 'blink right eye' });
      const mouthOpen = Math.min(
        100,
        (500 * Math.abs(res[i].mesh[13][1] - res[i].mesh[14][1])) /
          Math.abs(res[i].mesh[10][1] - res[i].mesh[152][1])
      );
      if (mouthOpen > 10)
        gestures.push({
          face: i,
          gesture: `mouth ${Math.trunc(mouthOpen)}% open`,
        });
      const chinDepth = res[i].mesh[152][2] || 0;
      const b = document.getElementById('debug');
      if (b !== undefined && b !== null) {
        b.textContent = chinDepth;
      }
      if (chinDepth < -42) {
        gestures.push({
          face: i,
          gesture: `head up`,
        });
      }
      if (chinDepth > 30)
        gestures.push({
          face: i,
          gesture: 'head down',
        });
    }
  }
  return gestures;
};

export function getLeftRightMargins(
  width: number,
  height: number,
  source: number
) {
  const dimensions = { left: 0, top: 0 };
  if (width > height) {
    dimensions.left = (width - source) / 2;
  }
  dimensions.top = (height - source) / 2;
  return dimensions;
}

export function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    console.log('navigator.userAgent', navigator.userAgent);
    return navigator.userAgent.match(toMatchItem);
  });
}
