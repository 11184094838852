export type VideoMatricsType = {
  openMouth: boolean;
  closeMouth: boolean;
  leftSide: boolean;
  rightSide: boolean;
  upSide: boolean;
  downSide: boolean;
  circle: boolean;
};

export interface Emotion {
  emotion: string;
  score: number;
}

export enum EMOTIONS {
  HAPPY = 'happy',
  SAD = 'sad',
  FEAR = 'fear',
  ANGRY = 'angry',
}
