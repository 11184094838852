import apiClient from '../network/apiClient';
import { checkRecordedVideos } from '../config/constants';
import { ExistingVideosResponseType } from './types';

export const chooseOptionApi = async ({
  token,
  brandId,
}: {
  token: string;
  brandId: string;
}): Promise<null | ExistingVideosResponseType> => {
  try {
    const userData = await apiClient.get(checkRecordedVideos(token, brandId));
    return userData.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};
