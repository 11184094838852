import styled from 'styled-components';
import { colors } from '../../../theme';

export const Background = styled.div`
  background: ${colors.white};
  box-shadow: 0px 8px 32px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 32px;
  margin: 32px 0;
`;

export const WhiteBox = ({ children }) => {
  return <Background>{children}</Background>;
};
