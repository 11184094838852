/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react';
import apiClient from '../../network/apiClient';
import { userLoginApi } from '../../config/constants';

const useLogin = (setLoader) => {
  const [isSubmited, setIsSubmited] = useState(false);
  const [apiError, setApiError] = useState(null);

  const login = async (payload) => {
    setApiError(null);
    const postObj = {
      username: payload.userName,
      password: payload.password,
    };
    try {
      const userLoginData = await apiClient.post(userLoginApi, postObj);
      localStorage.setItem('userLogin', JSON.stringify(userLoginData.data));
      localStorage.setItem('token', userLoginData.data.access_token);
      setIsSubmited(true);
    } catch (error) {
      setApiError(error.message || 'Api failed.');
      setLoader(false);
    }
  };

  return {
    isSubmited,
    setIsSubmited,
    apiError: apiError,
    login,
  };
};
export default useLogin;
