import styled from 'styled-components';
import { colors } from '../../theme';

export const DropDownCol = styled.div`
  position: absolute;
  background: white;
  box-shadow: 0px 8px 32px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 312px;
  z-index: 1;
  right: 0;
  padding: 8px 0;
`;

export const DropdownListCol = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;
