import React from 'react';
import AlertIcon from '../../../assets/alert-icon.svg';
import { Footer } from '../../../component/Footer';

import {
  BrandLogoIcon,
  Heading4,
  Paragraph2,
  ThemeBackground,
  WelcomeConatiner,
  WelcomeHeader,
  WhiteBox,
} from './styles';

const NotFound = () => {
  return (
    <ThemeBackground>
      <WelcomeConatiner>
        <WelcomeHeader>
          <img
            alt=""
            src="/static/media/footer_logo.9ba6617351afd185cbd7e0b73c25ace4.svg"
          />
          <br /> <br /> <br />
        </WelcomeHeader>
        <WhiteBox>
          <BrandLogoIcon width="48px" height="48px" src={AlertIcon} />
          <Heading4>Broken Link</Heading4>
          <Paragraph2>
            The Page that you are looking for doesn't exist.
          </Paragraph2>
        </WhiteBox>
      </WelcomeConatiner>
      <Footer />
    </ThemeBackground>
  );
};

export default NotFound;
