const messages = [
  'Slowly turn your head right',
  'TBA',
  'Move Diagonal To Right',
  'Move Little right and look up',
  'Look Up',
  'Move Little left and look up',
  'Move Diagonal to Left',
  'TBA',
  'Slowly turn your head left',
  'TBA',
  'Look a little down and Move left',
  'Move Diagonal to Bottom and Left',
  'Look Down',
  'Move Diagonal to Bottom and Right',
  'Look a little down and Move Right',
];

export default messages;

export const animationClass = (msg, styles) => {
  if (!messages[msg]) return null;
  switch (msg) {
    case 0: {
      return styles.moveRight;
    }
    case 1: {
      return { transform: 'rotate(350deg)' };
    }
    case 2: {
      return styles.diagonalRight;
    }
    case 3: {
      return { transform: 'rotate(285deg)' };
    }
    case 4: {
      return styles.up;
    }
    case 5: {
      return { transform: 'rotate(255deg)' };
    }
    case 6: {
      return styles.diagonalLeft;
    }
    case 7: {
      return { transform: 'rotate(185deg)' };
    }
    case 8: {
      return styles.moveLeft;
    }
    case 9: {
      return { transform: 'rotate(170deg)' };
    }
    case 10: {
      return styles.moveLeftBottom;
    }
    case 11: {
      return styles.movediagonalLeftBottom;
    }
    case 12: {
      return styles.moveDown;
    }
    case 13: {
      return styles.diagonalRightBottom;
    }
    case 14: {
      return styles.moveRightBottom;
    }
    default:
      break;
  }
};
