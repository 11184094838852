import React, { useEffect, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import CoverImageUpload from '../CoverImageUpload';
import useSubmitCoverImage from './useSubmitCoverImage';
import useSubmitBrandVideo from './useSubmitBrandVideo';
import useBrandVideoUpload from './useBrandVideoUpload';
import Error from '../../../component/Error';
import { ValidateNumeric, ValidateTitle } from '../../../utils/globalFunction';
import closeIcon from '../../../assets/close_icon.svg';
import {
  Body,
  Button,
  CloseIcon,
  Footer,
  FormColumn,
  Header,
  Heading,
  Heading5,
  HeadingCol,
  HeadingInput,
  LeftCol,
  Paragraph,
  RightCol,
  SubTitle,
  SwapColumn,
  TitleCol,
  VideoPlayer,
} from './styles';
import { colors } from '../../../theme';

const defaultStates = {
  title: '',
  swaps: '',
  coverImage: '',
  videoName: '',
};

const UploadNewVideoModal = ({
  onRequestClose,
  videoFile,
  addCoverFile,
  setAddCoverFile,
  setLoader,
  getBrandVideoDetails,
}) => {
  const [formState, setFormState] = useState(defaultStates);
  const [errors, setErrors] = useState({});
  const fileType = addCoverFile.type;
  const videoFileType = videoFile.type;
  const videoFileName = videoFile.name;
  const videoFileNameTrim = videoFileName
    .replace(/[0-9](?=.*[0-9])/g, '')
    .replace(/[.](?=.*[.])/g, '');
  console.log(videoFileNameTrim, 'videoFileNameTrim');
  const userId = JSON.parse(localStorage.getItem('userLogin'));
  const { saveCoverImg } = useSubmitCoverImage();
  const { saveBrandVideo, error } = useSubmitBrandVideo(setLoader);
  const { isSubmited, apiError, brandVideoUpload } =
    useBrandVideoUpload(setLoader);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('token');
    if (isSubmited && isAuthenticated) {
      setFormState(defaultStates);
      setLoader(false);
      onRequestClose();
      getBrandVideoDetails();
    }
  }, [isSubmited]);

  const submitLogo = async () => {
    const params = {
      type: fileType,
      data: addCoverFile,
      id: userId.brand_id,
      fileName: addCoverFile.name,
      location: 'cover_image',
    };
    return await saveCoverImg(params);
  };

  const submitBrandVideo = async () => {
    const params = {
      type: videoFileType,
      data: videoFile,
      id: userId.brand_id,
      fileName: videoFileNameTrim,
      location: 'brand',
    };
    return await saveBrandVideo(params);
  };

  const validateForm = (shouldSetError = true) => {
    const errors = {};
    const { title, swaps, coverImage } = formState;
    let error = ValidateTitle(title);
    if (error) {
      errors.title = 'The title ' + error;
    }
    error = ValidateNumeric(swaps);
    if (error) {
      errors.swaps = 'The swaps ' + error;
    }
    if (coverImage.length < 1) {
      errors.coverImage = true;
    }
    if (shouldSetError) setErrors(errors);
    return Object.keys(errors).length > 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length) {
      return;
    }
    setLoader(true);
    const submitVideoResponse = await submitVideoDetail();
    const payload = { ...formState, ...submitVideoResponse };
    brandVideoUpload(payload);
  };

  const handleChange = ({ target: { name, value } }) => {
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
      videoName: videoFileNameTrim,
    }));
    errors[name] = '';
    if (name === 'title') {
      const error = ValidateTitle(value);
      if (error) {
        errors.title = 'The title ' + error;
      }
    }
    if (name === 'swaps') {
      const error = ValidateNumeric(value);
      if (error) {
        errors.swaps = 'The swaps ' + error;
      }
    }
    return;
    setErrors(errors);
  };

  const onChangeAddCoverFile = (file) => {
    setAddCoverFile(file);
    setFormState((prevState) => ({
      ...prevState,
      coverImage: file.name,
    }));
  };

  const submitVideoDetail = async () => {
    const coverResponse = await submitLogo();
    const brandVideoResponse = await submitBrandVideo();
    return {
      coverImage: coverResponse.file_name,
      videoName: brandVideoResponse.file_name,
    };
  };
  const isSaveDisabled = useMemo(() => validateForm(false), [formState]);
  return (
    <>
      <Header>
        <HeadingCol>
          <Heading>Add video</Heading>
          <CloseIcon onClick={onRequestClose} src={closeIcon} />
        </HeadingCol>
        <SubTitle>Update your details about your video here</SubTitle>
      </Header>
      <form onSubmit={handleSubmit}>
        <Body>
          <LeftCol>
            <TitleCol>
              <SwapColumn>
                <Heading5>Title</Heading5>
              </SwapColumn>
              <FormColumn>
                <HeadingInput onChange={handleChange} name="title" />
                <Error touched={true} message={errors.title} />
              </FormColumn>
            </TitleCol>
            <TitleCol>
              <SwapColumn>
                <Heading5>Cover image</Heading5>
              </SwapColumn>
              <CoverImageUpload
                addCoverFile={addCoverFile}
                onChangeAddCoverFile={onChangeAddCoverFile}
              />
            </TitleCol>
            <TitleCol>
              <SwapColumn>
                <Heading5>Number of swaps allowed</Heading5>
                <Paragraph>
                  Enter the number of swaps you want to allow for this video.
                </Paragraph>
              </SwapColumn>
              <FormColumn>
                <HeadingInput
                  onChange={handleChange}
                  name="swaps"
                  textAlign="right"
                  width="140px"
                  type="number"
                />
                <Error touched={true} message={errors.swaps} />
              </FormColumn>
            </TitleCol>
          </LeftCol>
          <RightCol>
            {videoFile?.preview && (
              <VideoPlayer>
                <ReactPlayer
                  className="react-palyer"
                  width="296px"
                  height="170px"
                  url={videoFile?.preview}
                  playing={false}
                  controls={true}
                  onError={() => {
                    alert(
                      'The video format is not supported. Please try another video'
                    );
                    onRequestClose(false);
                  }}
                />
              </VideoPlayer>
            )}
          </RightCol>
        </Body>
        <Footer>
          {apiError && (
            <Error className="custom-error" touched={true} message={apiError} />
          )}
          {error && (
            <Error className="custom-error" touched={true} message={error} />
          )}
          <Button
            onClick={onRequestClose}
            background="transparent"
            color={colors.darkGreyText}
          >
            Cancel
          </Button>
          <Button disabled={isSaveDisabled}>Save</Button>
        </Footer>
      </form>
    </>
  );
};

export default UploadNewVideoModal;
