import styled from 'styled-components';
import { colors } from '../../../theme';

export const TableColumn = styled.div`
  height: calc(100vh - 320px);
  @media only screen and (max-width: 767px) {
    overflow: auto;
  }
`;

export const Table = styled.table`
  width: 100%;
  text-align: left;
  background: ${colors.white};
  border: 1px solid ${colors.navColor};
  border-radius: 8px;
  border-collapse: collapse;
  @media only screen and (max-width: 767px) {
    width: 600px;
  }
`;

export const THead = styled.thead`
  th {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${colors.subParagraph};
    background: ${colors.lightGray};
    padding: 12px 24px;
    border-bottom: 1px solid ${colors.navColor};
  }
`;

export const Tbody = styled.tbody`
  td {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.darkGrey};
    padding: 16px 24px;
    border-bottom: 1px solid ${colors.navColor};
    &:last-child {
      text-align: center;
    }
  }
  .approved {
    & span {
      color: ${colors.darkGreen};
      background: ${colors.lightGreen};
    }
  }
  .waiting {
    & span {
      color: ${colors.darkYellow};
      background: ${colors.lightYellow};
    }
  }
  .expired {
    & span {
      color: ${colors.darkBlue};
      background: ${colors.lightBlue};
    }
  }
  .rejected {
    & span {
      color: ${colors.errorRed};
      background: ${colors.lightRed};
    }
  }
`;

export const LightText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.subParagraph};
  margin: 0;
`;

export const EditIcon = styled.img`
  margin-top: 10px;
  cursor: pointer;
`;

export const StatusLabel = styled.span`
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  text-transform: capitalize;
`;

export const TableThumnail = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 63px;
    height: 40px;
    border-radius: 2px;
    margin-right: 16px;
    object-fit: cover;
    object-position: center;
  }
`;

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed ${colors.subParagraph};
  padding: 50px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.midGrey};
  max-width: 512px;
  width: 100%;
  margin: 0 auto;
`;

export const CameraIcon = styled.img``;

export const DropdownList = styled.li`
  text-align: left;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.darkGrey};
  &:hover {
    background: ${colors.lightGray};
  }
`;

export const EditColumn = styled.div`
  position: relative;
`;
