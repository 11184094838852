import React, { useEffect, useState } from 'react';

const useCountDown = () => {
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    if (timeLeft === 0) {
      console.log('TIME LEFT IS 0');
      setTimeLeft(null);
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  return [timeLeft, setTimeLeft];

  return (
    <React.Fragment>
      {timeLeft && <p>00:0{timeLeft}</p>}
      <button onClick={() => setTimeLeft(5)}>TEST</button>
    </React.Fragment>
  );
};

export default useCountDown;
