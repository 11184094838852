/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react';
import apiClient from '../../../network/apiClient';
import {
  userBrandVideoEditApi,
  userBrandVideoUploadApi,
} from '../../../config/constants';

const userBrandVideoUpload = (setLoader) => {
  const [isSubmited, setIsSubmited] = useState(false);
  const [apiError, setApiError] = useState(null);

  const brandVideoUpload = async (payload) => {
    setApiError(null);
    const postObj = {
      title: payload.title,
      cover_image: payload.coverImage,
      video_url: payload.videoName,
      swap_allowed: payload.swaps,
    };
    try {
      const userToken = localStorage.getItem('token') || '';
      await apiClient.post(userBrandVideoUploadApi, postObj, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setIsSubmited(true);
    } catch (error) {
      setApiError(error.message || 'Api failed.');
      setLoader(false);
    }
  };

  const brandVideoUpdate = async (payload, id) => {
    setApiError(null);
    try {
      const userToken = localStorage.getItem('token') || '';
      await apiClient.put(userBrandVideoEditApi(id), payload, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setIsSubmited(true);
      return true;
    } catch (error) {
      setApiError(error.message || 'Api failed.');
      setLoader(false);
    }
  };

  return {
    isSubmited,
    setIsSubmited,
    apiError: apiError,
    brandVideoUpload,
    brandVideoUpdate,
  };
};
export default userBrandVideoUpload;
