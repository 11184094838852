import styled from 'styled-components';
import { colors, fontFamily } from '../../../theme';

export const SelectText = styled.div`
  background: ${colors.white};
  color: ${colors.darkGrey};
  box-shadow: 0 8px 32px 8px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  max-width: 214px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.darkGrey};
  padding: 16px;
`;

export const ColorPickerSection = styled.div`
  left: 6px;
  position: absolute;
  top: 5px;
  .chrome-picker:nth-child(2) > :first-child {
    height: 216px;
    border-radius: 0 !important;
  }
  .chrome-picker:nth-child(2)
    > div:nth-child(2)
    > div:first-child
    > div:first-child {
    display: none;
  }
  .chrome-picker:nth-child(2)
    > div:nth-child(2)
    > div:last-child
    > div:last-child {
    display: none;
  }
  .chrome-picker:nth-child(2)
    > div:nth-child(2)
    > div:last-child
    > div:first-child
    > div
    > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
  }
  .chrome-picker {
    box-shadow: 0 8px 32px 8px rgba(0, 0, 0, 0.1) !important;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    .hue-horizontal {
      height: 13px !important;
      border-radius: 8px;
    }
    .hue-horizontal > div > div {
      background: transparent !important;
      border: 2px solid ${colors.white};
      width: 13px !important;
      height: 13px !important;
      margin-top: 1px;
    }
    input[spellcheck='false'] {
      width: 92px !important;
      height: 42px !important;
      background: ${colors.white};
      border: 1px solid ${colors.borderColor} !important;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important;
      border-radius: 6px !important;
      font-weight: 400;
      font-size: 16px !important;
      line-height: 24px;
      color: ${colors.subParagraph} !important;
      order: 1;
    }
    label {
      font-weight: 400;
      font-size: 14px !important;
      line-height: 14px !important;
      color: ${colors.subParagraph} !important;
      font-family: ${fontFamily.default};
      margin: 0 !important;
      order: 0;
    }
  }
`;
