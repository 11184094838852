import styled from 'styled-components';
import { colors } from '../theme';
import LoaderIcon from '../assets/loader.svg';

export const HeaderLogoColumn = styled.div`
  text-align: center;
  & h1 {
    margin: 25px 0 24px;
  }
`;

export const InputRow = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
`;

export const ConfirmationScreen = styled.div`
  text-align: center;
  margin-top: 32px;
  & h1 {
    margin: 0 0 8px;
  }
  & p {
    max-width: 303px;
    margin: 0 auto;
  }
`;

export const MailIcon = styled.img`
  margin-top: 55px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 40px;
  width: 448px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  background: ${colors.white};
  margin-top: 32px;
  @media only screen and (max-width: 767px) {
    width: 100%;
    border-radius: 0;
  }
`;

export const Loader = styled.div`
  background: url(${LoaderIcon}) no-repeat center center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  top: 0;
  bottom: 0;
`;

export const Heading1 = styled.h1`
  color: ${colors.white};
  font-size: 30px;
  font-weight: 800;
  line-height: 36px;
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 0;
`;

export const CaptchaColumn = styled.div`
  text-align: center;
  & .captcha-error {
    margin-bottom: 24px;
  }
`;

export const ArrowIcon = styled.img`
  margin-left: 4px;
  width: 12px;
`;

export const FormSubmissionError = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  background: rgba(221, 52, 68, 0.2);
  border-radius: 6px;
  padding: 10px;
  text-align: center;
  margin-bottom: 8px;
`;

export const CheckboxText = styled.span`
  color: ${colors.darkGrey};
  margin-left: 8px;
  a {
    color: ${colors.redColor};
    text-decoration: none;
  }
`;
