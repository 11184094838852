import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles';
import VideoMatricResults from './VideoMatricResults';
import { Footer } from '../component/Footer';
import VideoErrors from './VideoErrors';
import {
  detectFaceMoveToLeft,
  detectFaceMoveToRight,
  detectHeadDown,
  detectHeadUp,
  detectMob,
  detectMouthClose,
  detectMouthOpen,
} from './utils';
import { GestureResult } from '@vladmandic/human/dist/human.esm';
import { Slide, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useVideoRecordingSteps from './hooks/useVideoRecordingSteps';
import MobileCameraRecorder from './MobileCameraRecorder';
import { EMOTIONS } from './types';

const defaultVideoMatrics = {
  openMouth: false,
  closeMouth: false,
  leftSide: false,
  rightSide: false,
  upSide: false,
  downSide: false,
  circle: false,
  happy: false,
  frown: false,
  angry: false,
};

export const defaultVideoValidations = {
  distanceError: '',
  lightError: '',
  resolutionError: '',
  closedEyesError: '',
};

const Recording: React.FC<{ location: any }> = (props) => {
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [isModelLoaded, setIsModelLoaded] = useState(false);
  const [videoMatrics, setVideoMatrics] = useState(defaultVideoMatrics);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const { step, nextStep } = useVideoRecordingSteps();
  const [message, setMessage] = useState(
    'Position your face within the frame.'
  );
  const instructions = useRef(0);
  const [validationErrors, setValidationErrors] = useState(
    defaultVideoValidations
  );
  const [isRecordingOn, setIsRecordingOn] = useState(false);

  const resetVideoMatrics = () => {
    setVideoMatrics(defaultVideoMatrics);
  };

  let timer: null | ReturnType<typeof setTimeout> = null;
  const setTimerForRecordLoop = () => {
    if (!timer) {
      timer = setTimeout(() => {
        instructions.current = 7;
        nextStep(7);
        setVideoMatrics((prev) => ({ ...prev, circle: true }));
      }, 8000);
    }
  };

  const getDirection = (step: string): string | undefined => {
    const directions = ['left', 'right', 'up', 'down'];
    return directions.find((direction) => step.includes(direction));
  };

  const saveFaceMovements = (gestures: any) => {
    setVideoMatrics((prevState) => {
      if (
        !gestures.length ||
        (prevState.openMouth &&
          prevState.closeMouth &&
          prevState.leftSide &&
          prevState.rightSide &&
          prevState.upSide &&
          prevState.downSide &&
          prevState.circle &&
          prevState.happy &&
          prevState.angry &&
          prevState.frown)
      ) {
        return prevState;
      }
      const newVideoMatrics: Partial<typeof videoMatrics> = {};
      gestures.forEach(({ gesture }: { gesture: any }) => {
        switch (instructions.current) {
          case 0: {
            if (false) {
              newVideoMatrics.closeMouth = true;
              instructions.current = 1;
              nextStep(1);
            }
            break;
          }
          case 1: {
            if (true) {
              newVideoMatrics.openMouth = true;
              instructions.current = 2;
              nextStep(2);
            }
            break;
          }
          case 2: {
            if (true) {
              newVideoMatrics.leftSide = true;
              instructions.current = 3;
              nextStep(3);
            }
            break;
          }
          case 3: {
            if (true) {
              newVideoMatrics.rightSide = true;
              instructions.current = 4;
              nextStep(4);
            }
            break;
          }
          case 4: {
            if (true) {
              newVideoMatrics.upSide = true;
              instructions.current = 5;
              nextStep(5);
            }
            break;
          }
          case 5: {
            if (true) {
              newVideoMatrics.downSide = true;
              instructions.current = 6;
              nextStep(6);
            }
            break;
          }
          case 6: {
            setTimerForRecordLoop();
            break;
          }
          case 7: {
            if (true) {
              newVideoMatrics.happy = true;
              instructions.current = 8;
              nextStep(8);
            }
            break;
          }
          case 8: {
            if (true) {
              newVideoMatrics.frown = true;
              instructions.current = 9;
              nextStep(9);
            }
            break;
          }
          case 9: {
            if (true) {
              newVideoMatrics.angry = true;
              instructions.current = 10;
              nextStep(10);
            }
            break;
          }
          default: {
            newVideoMatrics.closeMouth = true;
          }
        }
      });
      return { ...prevState, ...newVideoMatrics };
    });
  };

  const setError = (error: Partial<typeof defaultVideoValidations>) => {
    setValidationErrors((prevState) => ({
      ...prevState,
      ...error,
    }));
  };
  useEffect(() => {
    if (step && isRecordingOn) {
      toast.dismiss();
      setMessage(step);
    }
  }, [step]);

  useEffect(() => {
    const isMobileDevice = detectMob();
    setIsMobileDevice(isMobileDevice);
  }, []);

  const isAllStepCompleted = useMemo(
    () => Object.values(videoMatrics).filter((step) => !step).length === 0,
    [videoMatrics]
  );
  const showMessage = (text: string) => {
    toast(text);
  };

  return (
    <div style={styles.videoContainer}>
      <ToastContainer
        position="top-center"
        autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        theme="colored"
        transition={Slide}
      />
      {isMobileDevice ? (
        <MobileCameraRecorder
          isCameraOpen={isCameraOpen}
          setIsCameraOpen={setIsCameraOpen}
          saveFaceMovements={saveFaceMovements}
          isModelLoaded={isModelLoaded}
          setIsModelLoaded={setIsModelLoaded}
          resetVideoMatrics={resetVideoMatrics}
          setError={setError}
          isAllStepCompleted={isAllStepCompleted}
          videoId={props.location?.state?.brandId}
          isRecordingOn={isRecordingOn}
          setIsRecordingOn={setIsRecordingOn}
          direction={getDirection(step)}
        />
      ) : (
        <MobileCameraRecorder
          isCameraOpen={isCameraOpen}
          setIsCameraOpen={setIsCameraOpen}
          saveFaceMovements={saveFaceMovements}
          isModelLoaded={isModelLoaded}
          setIsModelLoaded={setIsModelLoaded}
          resetVideoMatrics={resetVideoMatrics}
          setError={setError}
          isAllStepCompleted={isAllStepCompleted}
          videoId={props.location?.state?.brandId}
          isRecordingOn={isRecordingOn}
          setIsRecordingOn={setIsRecordingOn}
          direction={getDirection(step)}
        />
      )}
      {isCameraOpen && !isAllStepCompleted && (
        <VideoErrors errors={validationErrors} />
      )}
      {false && (
        <VideoMatricResults
          isRecordingOn={isRecordingOn}
          results={videoMatrics}
        />
      )}
      <Footer />
    </div>
  );
};

export default Recording;
