import { useHistory } from 'react-router';
import TickImg from '../assets/tick_icon.svg';
import {
  Button,
  ColorBox,
  Paragraph,
  SuccessBox,
  SuccessHeading,
  SuccessParagraph,
  TickIcon,
  WelcomeConatiner,
} from './styles';

const ThankYou: React.FC = () => {
  const history = useHistory();
  const goBack = () => {
    history.push('/');
  };
  return (
    <WelcomeConatiner>
      <SuccessBox>
        <ColorBox>
          <TickIcon src={TickImg} />
        </ColorBox>
        <SuccessHeading>Video Link</SuccessHeading>
        <SuccessParagraph>
          Please look out for an email with the link to your video.
        </SuccessParagraph>
        <Button onClick={goBack}>Ok</Button>
      </SuccessBox>
      <Paragraph>
        On occasions emails can drop into your junk folder
        <br />
        so please keep an eye out.
      </Paragraph>
    </WelcomeConatiner>
  );
};

export default ThankYou;
