import React from 'react';
import styled from 'styled-components';
import { Container } from '../../component/Container';

const StyledHeader = styled.h1`
  text-align: center;
  opacity: 0.8;
  font-size: 2rem;
  color: #fff;
  font-weight: 700;
  max-width: 800px;
`;

const StyledText = styled.p`
  text-align: center;
  opacity: 0.8;
  font-size: 1.5rem;
  color: #fff;
  font-weight: 400;
  max-width: 800px;
`;

interface DesktopMessageProps {
  isChromeOniOS?: boolean;
}

const DesktopMessage: React.FC<DesktopMessageProps> = ({ isChromeOniOS }) => {
  return (
    <Container>
      <StyledHeader>Oops!</StyledHeader>
      {isChromeOniOS ? (
        <StyledText>
          Looks like you are using Chrome on an iOS device. Please come back
          using Safari as your browser.
        </StyledText>
      ) : (
        <StyledText>
          Looks like you aren't using a mobile device or tablet, please visit us
          again from your phone or tablet
        </StyledText>
      )}
    </Container>
  );
};

export default DesktopMessage;
