import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ModalVideo from 'react-modal';
import useVideos from './useVideos';
import Video from './VideoElement';
import ArrowWhite from '../assets/arrow_next_white.svg';
import UserLogo from '../component/UserLogo';
import { Footer } from '../component/Footer';
import { ContinueButton } from '../component/ContinueButton';
import {
  ArrowIcon,
  Heading1,
  Loader,
  Paragraph,
  ThemeBackground,
  VideoConatiner,
  VideoPlayer,
  WelcomeConatiner,
  WelcomeHeader,
} from './styles';

const Welcome = (props) => {
  const history = useHistory();
  const brandId = props.match.params.brand_id;
  const [loader, setLoader] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const { getVideos, brandVideos } = useVideos(brandId, setLoader);
  const [isOpen, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    if (brandId) {
      setLoader(true);
      getVideos();
    }
  }, [brandId]);

  useEffect(() => {
    if (brandVideos && brandVideos.length === 1) {
      setSelectedVideo(brandVideos[0].id);
    }
  }, [brandVideos]);

  useEffect(() => {
    if (brandVideos?.id) {
      localStorage.setItem(
        'termCondition',
        brandVideos?.term_and_conditions[0]?.name
      );
    }
  }, [brandVideos]);

  const submit = () => {
    history.push({ pathname: '/login', state: { brandId: selectedVideo } });
  };

  const closeModal = () => {
    setVideoUrl('');
    setOpen(false);
  };
  const customStyles = {
    overlay: {
      background: 'rgba(0, 0, 0, 0.4)',
      zIndex: 20,
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 20,
      maxWidth: '80%',
    },
  };
  return (
    <ThemeBackground>
      {loader && <Loader />}
      <ModalVideo
        style={customStyles}
        isOpen={isOpen}
        onRequestClose={() => closeModal(false)}
      >
        <VideoPlayer controls autoPlay width="100%" playsinline>
          <source src={videoUrl} type="video/mp4" />
        </VideoPlayer>
      </ModalVideo>
      <WelcomeConatiner>
        <WelcomeHeader>
          <UserLogo brandId={brandId} />
          <Heading1>Welcome to our Video Face Swapping</Heading1>
          <Paragraph>
            In a few simple steps, you can swap your face onto the video and see
            yourself as your hero. To get started, preview and select a video
            and then hit continue.
          </Paragraph>
        </WelcomeHeader>
        <VideoConatiner>
          {brandVideos?.brand_videos?.map((item) => (
            <Video
              item={item}
              key={item.id}
              selectedVideo={selectedVideo}
              setSelectedVideo={setSelectedVideo}
            />
          ))}
        </VideoConatiner>
        <ContinueButton disabled={!selectedVideo} onClick={submit}>
          Continue
          <ArrowIcon src={ArrowWhite} />
        </ContinueButton>
      </WelcomeConatiner>
      <Footer />
    </ThemeBackground>
  );
};

export default Welcome;
