import styled from 'styled-components';
import { colors } from '../../../theme';

export const LogoUploadSection = styled.div``;

export const LogoPreviewCol = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const LogoColumn = styled.div`
  width: 141px;
  height: 84px;
  cursor: pointer;
`;

export const LogoPreview = styled.img`
  width: 100%;
  object-position: center;
  object-fit: cover;
  height: 100%;
  border-radius: 6px;
`;

export const GalleryIcon = styled.img`
  margin-bottom: 10px;
`;

export const DefaultCover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 141px;
  height: 84px;
  background: ${colors.lightGray};
  border: 1px dashed ${colors.videoBorder};
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.darkGrey};
`;

export const CoverChange = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: white;
  position: absolute;
  margin: 0;
  bottom: 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
`;

export const CoverChangeCol = styled.div`
  position: relative;
  height: 100%;
`;
