import styled from 'styled-components';

export const FormSubmissionError = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  background: rgba(221, 52, 68, 0.2);
  border-radius: 6px;
  padding: 10px;
  text-align: center;
`;
export const FormSubmissionSuccess = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  background: rgba(52, 221, 68, 0.3);
  border-radius: 6px;
  padding: 10px;
  text-align: center;
`;
