import { Footer, FooterText, TextRed } from './styles';

export const DashboardFooter = () => {
  return (
    <Footer>
      <FooterText>&copy; 2022 Chose2. All rights reserved.</FooterText>
      <FooterText>
        Powered by <TextRed>Chose2</TextRed>
      </FooterText>
    </Footer>
  );
};
