import styled from 'styled-components';

export const DashboardBody = styled.section``;

export const customStyles = {
  overlay: {
    background: 'rgba(55, 65, 81, 0.8)',
    zIndex: 20,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 512,
    background: '#fff',
    boxShadow:
      '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    border: 'none',
    padding: 24,
  },
};
