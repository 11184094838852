import React from 'react';
import { ErrorColumn } from './styles';

const Error = ({ touched, message, className }) => {
  if (!touched) {
    return null;
  }
  const error = Array.isArray(message) ? message.join(',') : message;
  if (error) {
    return <ErrorColumn className={className}>{error}</ErrorColumn>;
  }
  return null;
};

export default Error;
