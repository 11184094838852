/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react';
import apiClient from '../network/apiClient';
import { brandVideosApi } from '../config/constants';

const useVideos = (brandId, setLoader) => {
  const [brandVideos, setBrandVideos] = useState(null);
  const getVideos = async () => {
    try {
      const apiResponse = await apiClient.get(brandVideosApi, {
        params: { uuid: brandId },
      });
      setBrandVideos(apiResponse.data);
      setLoader(false);
    } catch (error) {
      console.log(error, 'ERROR');
    }
  };

  return {
    getVideos,
    brandVideos,
  };
};
export default useVideos;
