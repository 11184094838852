import styled from 'styled-components';
import { colors } from '../../theme';

export const HeaderLogoColumn = styled.div`
  text-align: center;
  & h1 {
    margin: 25px 0 24px;
  }
`;

export const Heading1 = styled.h1`
  color: ${colors.white};
  font-size: 30px;
  font-weight: 800;
  line-height: 36px;
  margin: 32px 0 8px;
`;
