const data = require('../HS_yaw_pitch_dict.json');

function roundNearest5(x, n = 10) {
  return x < 0 ? x - (x % n) : x - (x % n) + n;
}

const uniqueElementsBy = (arr, fn) =>
  arr.reduce((acc, v) => {
    if (!acc.some((x) => fn(v, x))) acc.push(v);
    return acc;
  }, []);

const yawPitchData = Object.keys(data);
const validYawPitchData = [];
yawPitchData.forEach((k) => {
  const item = data[k];
  const child = Object.keys(item);
  child.forEach((l) => {
    const childItem = item[l];
    if (childItem.count > 0) {
      validYawPitchData.push(childItem);
    }
  });
});
const getValue = (value) => {
  const numberValue = parseInt(value);
  if (Number.isNaN(numberValue)) return 0;
  return numberValue;
};

let d = validYawPitchData.map((f) => ({
  yaw: roundNearest5(getValue(f.yaw_bin), 10),
  pitch: roundNearest5(getValue(f.pitch_bin)),
}));
d = uniqueElementsBy(d, (a, b) => a.yaw === b.yaw && a.pitch === b.pitch);
console.log('validYawPitchData', d);

const filterArray = (source, target) => {
  const filteredArray = [];
  const remainingArray = [];
  source.forEach((value) => {
    const findMatching = target.find(
      (item) => item.yaw === value.yaw && item.pitch === value.pitch
    );
    if (findMatching) {
      filteredArray.push(value);
    } else {
      remainingArray.push(value);
    }
  });
  return { filteredArray, remainingArray };
};

const moveRightFrames = (allFrames = []) => {
  const frames = [
    { yaw: 60, pitch: 0 },
    { yaw: 50, pitch: 0 },
    { yaw: 40, pitch: 0 },
    { yaw: 30, pitch: 0 },
    { yaw: 20, pitch: 0 },
    { yaw: 10, pitch: 0 },
    { yaw: 60, pitch: -5 },
    { yaw: 50, pitch: -5 },
    { yaw: 40, pitch: -5 },
    { yaw: 30, pitch: -5 },
    { yaw: 20, pitch: -5 },
    { yaw: 10, pitch: -5 },
  ];
  return filterArray(allFrames, frames);
};

const result = moveRightFrames(d);

const step2 = (allFrames) => {
  const frames = [
    { yaw: 60, pitch: -10 },
    { yaw: 50, pitch: -10 },
    { yaw: 40, pitch: -10 },
    { yaw: 30, pitch: -10 },
    { yaw: 20, pitch: -10 },
    { yaw: 10, pitch: -10 },
    { yaw: 60, pitch: -15 },
    { yaw: 50, pitch: -15 },
    { yaw: 40, pitch: -15 },
    { yaw: 30, pitch: -15 },
    { yaw: 20, pitch: -15 },
    { yaw: 10, pitch: -15 },
  ];
  return filterArray(allFrames, frames);
};
const resultsStep2 = step2(result.remainingArray);
const moveRightDiagonalFrames = (allFrames) => {
  const frames = [
    { yaw: 60, pitch: 50 },
    { yaw: 60, pitch: 45 },
    { yaw: 60, pitch: 40 },
    { yaw: 60, pitch: -35 },
    { yaw: 50, pitch: -35 },
    { yaw: 40, pitch: -35 },
    { yaw: 30, pitch: -35 },
    { yaw: 60, pitch: -30 },
    { yaw: 50, pitch: -30 },
    { yaw: 40, pitch: -30 },
    { yaw: 30, pitch: -30 },
    { yaw: 20, pitch: -30 },
    { yaw: 60, pitch: -25 },
    { yaw: 50, pitch: -25 },
    { yaw: 40, pitch: -25 },
    { yaw: 30, pitch: -25 },
    { yaw: 20, pitch: -25 },
    { yaw: 60, pitch: -20 },
    { yaw: 50, pitch: -20 },
    { yaw: 40, pitch: -20 },
    { yaw: 30, pitch: -20 },
    { yaw: 20, pitch: -20 },
    { yaw: 60, pitch: -15 },
    { yaw: 50, pitch: -15 },
    { yaw: 40, pitch: -15 },
    { yaw: 30, pitch: -15 },
    { yaw: 30, pitch: -15 },
    { yaw: 20, pitch: -15 },
    { yaw: 10, pitch: -15 },
    { yaw: 40, pitch: -15 },
    { yaw: 15, pitch: -15 },
  ];
  return filterArray(allFrames, frames);
};

const result2 = moveRightDiagonalFrames(resultsStep2.remainingArray);

const moveTopFrames = (allFrames) => {
  const frames = [
    { yaw: 0, pitch: -30 },
    { yaw: 0, pitch: -25 },
    { yaw: 0, pitch: -20 },
    { yaw: 0, pitch: -15 },
    { yaw: 0, pitch: -10 },
  ];
  return filterArray(allFrames, frames);
};

const result3 = moveTopFrames(result2.remainingArray);

const moveLeftDiagonalFrames = (allFrames) => {
  const frames = [
    { yaw: -60, pitch: -30 },
    { yaw: -50, pitch: -30 },
    { yaw: -40, pitch: -30 },
    { yaw: -30, pitch: -30 },
    { yaw: -60, pitch: -25 },
    { yaw: -50, pitch: -25 },
    { yaw: -40, pitch: -25 },
    { yaw: -30, pitch: -25 },
    { yaw: -20, pitch: -25 },
    { yaw: -60, pitch: -20 },
    { yaw: -50, pitch: -20 },
    { yaw: -40, pitch: -20 },
    { yaw: -30, pitch: -20 },
    { yaw: -20, pitch: -20 },
    { yaw: -30, pitch: -15 },
    { yaw: -20, pitch: -15 },
    { yaw: -10, pitch: -15 },
    { yaw: -40, pitch: -15 },
  ];
  return filterArray(allFrames, frames);
};

const result4 = moveLeftDiagonalFrames(result3.remainingArray);

const step7Frames = (allFrames) => {
  const frames = [
    { yaw: -60, pitch: -10 },
    { yaw: -50, pitch: -10 },
    { yaw: -40, pitch: -10 },
    { yaw: -30, pitch: -10 },
    { yaw: -20, pitch: -10 },
    { yaw: -10, pitch: -10 },
    { yaw: -60, pitch: -15 },
    { yaw: -50, pitch: -15 },
    { yaw: -40, pitch: -15 },
    { yaw: -30, pitch: -15 },
    { yaw: -20, pitch: -15 },
    { yaw: -10, pitch: -15 },
  ];
  return filterArray(allFrames, frames);
};
const step7Result = step7Frames(result4.remainingArray);

const moveLeftFrames = (allFrames) => {
  const frames = [
    { yaw: -60, pitch: 0 },
    { yaw: -50, pitch: 0 },
    { yaw: -40, pitch: 0 },
    { yaw: -30, pitch: 0 },
    { yaw: -20, pitch: 0 },
    { yaw: -10, pitch: 0 },
    { yaw: -60, pitch: 5 },
    { yaw: -50, pitch: 5 },
    { yaw: -40, pitch: 5 },
    { yaw: -30, pitch: 5 },
    { yaw: -20, pitch: 5 },
    { yaw: -10, pitch: 5 },
    { yaw: -60, pitch: -5 },
    { yaw: -50, pitch: -5 },
    { yaw: -40, pitch: -5 },
    { yaw: -30, pitch: -5 },
    { yaw: -20, pitch: -5 },
    { yaw: -10, pitch: -5 },
  ];
  return filterArray(allFrames, frames);
};

const result5 = moveLeftFrames(step7Result.remainingArray);

const step9Frames = (allFrames = []) => {
  const frames = [
    { yaw: -60, pitch: 10 },
    { yaw: -50, pitch: 10 },
    { yaw: -40, pitch: 10 },
    { yaw: -30, pitch: 10 },
    { yaw: -20, pitch: 10 },
    { yaw: -10, pitch: 10 },
    { yaw: -60, pitch: 15 },
    { yaw: -50, pitch: 15 },
    { yaw: -40, pitch: 15 },
    { yaw: -30, pitch: 15 },
    { yaw: -20, pitch: 15 },
    { yaw: -10, pitch: 15 },
  ];
  return filterArray(allFrames, frames);
};
const step9Result = step9Frames(result5.remainingArray);
const moveBottomLeftFrames = (allFrames) => {
  const frames = [
    { yaw: -60, pitch: 20 },
    { yaw: -50, pitch: 20 },
    { yaw: -40, pitch: 20 },
    { yaw: -30, pitch: 20 },
    { yaw: -20, pitch: 20 },
    { yaw: -10, pitch: 20 },
    { yaw: -30, pitch: 25 },
    { yaw: -30, pitch: 30 },
    { yaw: -30, pitch: 35 },
    { yaw: -20, pitch: 25 },
    { yaw: -25, pitch: 30 },
    { yaw: -30, pitch: 35 },
  ];
  return filterArray(allFrames, frames);
};

const result6 = moveBottomLeftFrames(step9Result.remainingArray);

const moveBottomLeftDiagonalFrames = (allFrames) => {
  const frames = [
    { yaw: -60, pitch: 30 },
    { yaw: -50, pitch: 30 },
    { yaw: -40, pitch: 30 },
    { yaw: -30, pitch: 30 },
    { yaw: -60, pitch: 25 },
    { yaw: -50, pitch: 25 },
    { yaw: -40, pitch: 25 },
    { yaw: -30, pitch: 25 },
    { yaw: -60, pitch: 20 },
    { yaw: -50, pitch: 20 },
    { yaw: -40, pitch: 20 },
    { yaw: -30, pitch: 20 },
    { yaw: -30, pitch: 40 },
    { yaw: -30, pitch: 50 },
    { yaw: -30, pitch: 15 },
    { yaw: -20, pitch: 15 },
    { yaw: -10, pitch: 15 },
    { yaw: -40, pitch: 15 },
  ];
  return filterArray(allFrames, frames);
};

const result7 = moveBottomLeftDiagonalFrames(result6.remainingArray);

const moveBottomFrames = (allFrames) => {
  const frames = [
    { yaw: 10, pitch: 50 },
    { yaw: 10, pitch: 45 },
    { yaw: 10, pitch: 35 },
    { yaw: 10, pitch: 30 },
    { yaw: 10, pitch: 25 },
    { yaw: 10, pitch: 20 },
    { yaw: 10, pitch: 15 },
    { yaw: -20, pitch: 50 },
    { yaw: -20, pitch: 45 },
    { yaw: -20, pitch: 40 },
    { yaw: -20, pitch: 35 },
    { yaw: -20, pitch: 30 },
    { yaw: -20, pitch: 15 },
    { yaw: -10, pitch: 30 },
    { yaw: -10, pitch: 15 },
    { yaw: 0, pitch: 50 },
    { yaw: 0, pitch: 45 },
    { yaw: 0, pitch: 40 },
    { yaw: 0, pitch: 35 },
    { yaw: 0, pitch: 30 },
    { yaw: 0, pitch: 15 },
    { yaw: -30, pitch: 45 },
  ];
  return filterArray(allFrames, frames);
};

const result8 = moveBottomFrames(result7.remainingArray);

const moveBottomRightDiagonalFrames = (allFrames) => {
  const frames = [
    { yaw: 70, pitch: 60 },
    { yaw: 70, pitch: 50 },
    { yaw: 60, pitch: 50 },
    { yaw: 60, pitch: 45 },
    { yaw: 60, pitch: 40 },
    { yaw: 60, pitch: 30 },
    { yaw: 50, pitch: 30 },
    { yaw: 40, pitch: 30 },
    { yaw: 30, pitch: 15 },
    { yaw: 20, pitch: 15 },
    { yaw: 10, pitch: 15 },
    { yaw: 30, pitch: 30 },
    { yaw: 40, pitch: 15 },
  ];
  return filterArray(allFrames, frames);
};

const result9 = moveBottomRightDiagonalFrames(result8.remainingArray);

const moveBottomRightFrames = (allFrames) => {
  const frames = [
    { yaw: 60, pitch: 15 },
    { yaw: 50, pitch: 15 },
    { yaw: 40, pitch: 15 },
    { yaw: 30, pitch: 15 },
    { yaw: 20, pitch: 15 },
    { yaw: 10, pitch: 15 },
    { yaw: 15, pitch: 15 },
    { yaw: 20, pitch: 20 },
    { yaw: 30, pitch: 20 },
    { yaw: 40, pitch: 20 },
    { yaw: 50, pitch: 20 },
    { yaw: 60, pitch: 20 },
    { yaw: 60, pitch: 10 },
    { yaw: 50, pitch: 10 },
    { yaw: 40, pitch: 10 },
    { yaw: 30, pitch: 10 },
    { yaw: 20, pitch: 10 },
    { yaw: 10, pitch: 10 },
  ];
  return filterArray(allFrames, frames);
};

const result10 = moveBottomRightFrames(result9.remainingArray);

const moveTopRightFrames = (allFrames) => {
  const frames = [
    { yaw: 10, pitch: -30 },
    { yaw: 10, pitch: -25 },
    { yaw: 10, pitch: -20 },
    { yaw: 10, pitch: -15 },
    { yaw: 20, pitch: 30 },
    { yaw: 20, pitch: 25 },
    { yaw: 20, pitch: 20 },
    { yaw: 20, pitch: 15 },
  ];
  return filterArray(allFrames, frames);
};

const result11 = moveTopRightFrames(result10.remainingArray);

const moveTopLeftFrames = (allFrames) => {
  const frames = [
    { yaw: -20, pitch: -30 },
    { yaw: -20, pitch: -25 },
    { yaw: -20, pitch: -20 },
    { yaw: -20, pitch: -15 },
    { yaw: -10, pitch: -30 },
    { yaw: -10, pitch: -25 },
    { yaw: -10, pitch: -20 },
    { yaw: -10, pitch: -15 },
  ];
  return filterArray(allFrames, frames);
};

const result12 = moveTopLeftFrames(result11.remainingArray);
console.log('step 1', result.filteredArray.length);
console.log('step 2', resultsStep2.filteredArray.length);
console.log('step 3', result2.filteredArray.length);
console.log('step 4', result11.filteredArray.length);
console.log('step 5', result3.filteredArray.length);
console.log('step 6', result12.filteredArray.length);
console.log('step 7', result4.filteredArray.length);
console.log('step 8', step7Result.filteredArray.length);
console.log('step 9', result5.filteredArray.length);
console.log('step 10', step9Result.filteredArray.length);
console.log('step 11', result6.filteredArray.length);
console.log('step 12', result7.filteredArray.length);
console.log('step 13', result8.filteredArray.length);
console.log('step 14', result9.filteredArray.length);
console.log('step 15', result10.filteredArray.length);
console.log('remaining', result12.remainingArray);
console.log('step7Result', step7Result);
export default [
  result.filteredArray,
  resultsStep2.filteredArray,
  result2.filteredArray,
  result11.filteredArray, // top with little right
  result3.filteredArray,
  result12.filteredArray, // top with little left
  result4.filteredArray,
  step7Result.filteredArray,
  result5.filteredArray,
  step9Result.filteredArray,
  result6.filteredArray,
  result7.filteredArray,
  result8.filteredArray,
  result9.filteredArray,
  result10.filteredArray,
];
