import styled from 'styled-components';
import { colors } from '../../../theme';

export const Footer = styled.footer`
  padding: 32px 0;
  border-top: 1px solid ${colors.borderColor};
  position: ${(props) => props.positionCheck || 'static'};
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: calc(100% - 140px);
  display: flex;
  justify-content: space-between;
`;

export const FooterText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.subParagraph};
  margin: 0;
`;

export const TextRed = styled.span`
  color: #c9011a;
`;
