/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useState } from 'react';
import { S3ParamsType, S3Upload } from '../../../recording/s3upload';

const useSubmitCoverImage = (): {
  isSaved: boolean;
  uploading: boolean;
  error: string;
  saveCoverImg: (
    params: S3ParamsType,
    color: string
  ) => Promise<{ file_name: string } | undefined>;
  resetCoverImgStates: () => void;
} => {
  const [uploading, setUploading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [error, setError] = useState('');
  const saveCoverImg = useCallback(async (params: S3ParamsType) => {
    try {
      setUploading(true);
      // FOR DEBUG IN STAGE
      const response = await S3Upload(params);
      if (response) {
        setIsSaved(true);
        setUploading(false);
        return response;
      }
    } catch (error: any) {
      setError(error?.message || 'Video submition failed.');
    }
  }, []);
  const resetCoverImgStates = () => {
    setUploading(false);
    setIsSaved(false);
    setError('');
  };
  return {
    isSaved,
    uploading,
    error,
    saveCoverImg,
    resetCoverImgStates,
  };
};
export default useSubmitCoverImage;
