import styled, { keyframes } from 'styled-components';
import { colors } from '../../../theme';

export const Overlay = styled.div`
  background: ${colors.darkGrey};
  height: 100%;
  width: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(8px);
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 10;
`;

const rotate = keyframes`
0% {
transform: rotate(00deg);
}
3.6%{transform: rotate(10deg);}
7.2%{transform: rotate(20deg);}
10.8%{transform: rotate(30deg);}
14.4%{transform: rotate(40deg);}
18%{transform: rotate(50deg);}
25% {
transform: rotate(90deg);
}
50% {
transform: rotate(180deg);
}
75% {
transform: rotate(270deg);
}
100% {
transform: rotate(360deg);
}
`;

export const Container = styled.div`
  position: absolute;
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: 0.3s;
  // animation: rotate 20s infinite;
  // animation: ${rotate} 20s linear infinite;
  z-index: 100;
`;

export const Line = styled.div`
  height: 4px;
  background: white;
  width: 150px;
  margin-left: 150px;
`;

const styles: any = {
  arrow: {
    transform: 'translate(53px, 0)',
    position: 'absolute',
  },
  moveRight: {
    transform: 'rotate(360deg) ',
  },
  diagonalRight: {
    transform: 'rotate(315deg) ',
  },
  up: {
    transform: 'rotate(270deg) ',
  },
  diagonalLeft: {
    transform: 'rotate(225deg) ',
  },
  moveLeft: {
    transform: 'rotate(180deg) ',
  },
  moveLeftBottom: {
    transform: 'rotate(160deg) ',
  },
  movediagonalLeftBottom: {
    transform: 'rotate(135deg) ',
  },
  moveDown: {
    transform: 'rotate(90deg) ',
  },
  diagonalRightBottom: {
    transform: 'rotate(45deg) ',
  },
  moveRightBottom: {
    transform: 'rotate(15deg) ',
  },
  leftUp: {
    transform: 'rotate(-90deg) ',
  },
  moveRight1: {
    transform: 'translate(90px, 0px) ',
  },
  diagonalRight1: {
    transform: 'rotate(315deg) ',
  },
  up1: {
    transform: 'rotate(270deg) translate(90px, 0px)',
  },
  diagonalLeft1: {
    transform: 'rotate(225deg) translate(90px, 0px)',
  },
  moveLeft1: {
    transform: 'rotate(180deg) translate(90px, 0px)',
  },
  moveLeftBottom1: {
    transform: 'rotate(160deg) translate(90px, 0px)',
  },
  movediagonalLeftBottom1: {
    transform: 'rotate(135deg) translate(90px, 0px)',
  },
  moveDown1: {
    transform: 'rotate(90deg) translate(90px, 0px)',
  },
  diagonalRightBottom1: {
    transform: 'rotate(45deg) translate(90px, 0px)',
  },
  moveRightBottom1: {
    transform: 'rotate(70deg) translate(90px, 0px)',
  },
  leftUp1: {
    transform: 'rotate(-90deg) translate(90px, -20px)',
  },
};

export default styles;
