/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';
import { colors, ColorSmallBox } from '../theme';

export const ThemeBackground = styled.div`
  background: ${colors.darkGrey};
  padding: 48px 90px;
  @media only screen and (max-width: 767px) {
    padding: 48px 24px;
    height: auto;
  }
`;

export const WelcomeConatiner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WelcomeHeader = styled.div`
  text-align: center;
`;

export const BrandLogoIcon = styled.img`
  width: 100px;
  height: 100px;
  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
`;

export const Heading1 = styled.h1`
  color: ${colors.white};
  font-size: 24px;
  font-weight: 800;
  line-height: 36px;
  margin: 32px 0 8px;
  @media (max-width: 768px) {
    margin-top: 0;
    font-size: 18px;
  }
`;

export const Paragraph = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.borderColor};
  max-width: 470px;
  margin: 0 auto;
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const VideoCaptureContainer = styled.div`
  display: block;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
`;

export const Button = styled.button<{ color?: string }>`
  padding: 9px 17px;
  border-radius: 20px;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background: ${({ color }) =>
    color === 'green' ? colors.greenColor : colors.redColor};
  width: 100%;
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  max-width: 300px;
  font-family: 'Inter', sans-serif;
  transition: transform 250ms;
  &:hover {
    transform: translateY(-2px);
  }
  & img {
    margin-left: 5px;
    width: 12px;
  }
  &:disabled {
    background: ${colors.errorBorder};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    color: ${colors.errorRed};
  }
`;
export const CTAButton = styled(Button)`
  max-width: initial;
  border-radius: 4px;
`;
export const FaceAvtarContainer = styled.div`
  text-align: center;
  margin: 32px 0;
`;

export const FaceAvtarRound = styled.img`
  width: 248px;
  height: 248px;
`;

export const FaceAvtarSquare = styled.div`
  width: 300px;
  height: 300px;
  margin: 0 auto;
  position: relative;
  border-radius: 50%;
  &[data-mobile='true'] {
    width: 248px;
    height: 248px;
  }
`;

export const BorderColOne = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const BorderColTwo = styled.div`
  position: absolute;
  top: 0;
  right: 44px;
`;

export const BorderColThree = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const BorderColFour = styled.div`
  position: absolute;
  bottom: 7px;
  right: 44px;
`;

export const ApiError = styled.p`
  font-size: 14px;
  color: red;
  font-weight: 600;
`;

export const BorderLineOne = styled.span<{
  lineTop?: string;
  lineLeft?: string;
}>`
  position: absolute;
  top: ${({ lineTop }) => lineTop || '0'};
  left: ${({ lineLeft }) => lineLeft || '0'};
  width: 40px;
  height: 6px;
  background: ${colors.white};
  border-radius: 4px;
`;

export const BorderLineTwo = styled.span<{
  lineTop?: string;
  lineLeft?: string;
}>`
  position: absolute;
  top: ${({ lineTop }) => lineTop || '21px'};
  left: ${({ lineLeft }) => lineLeft || '-15px'};
  width: 36px;
  height: 6px;
  background: ${colors.white};
  border-radius: 4px;
  transform: rotate(90deg);
`;

export const FaceRecordView = styled.video`
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
  transform: scaleX(-1);
  border-radius: 50%;
  &[data-mobile='false'] {
    z-index: -9;
    opacity: 0;
    position: fixed;
  }
  &[data-mobile='true'] {
    width: 246px;
    height: 246px;
    transform: scaleX(-1);
  }
`;

export const DownloadLink = styled.a`
  text-decoration: underline;
  color: ${colors.greenColor};
`;

export const ArrowIcon = styled.img``;

export const TickIcon = styled.img``;

export const SuccessBox = styled.div`
  background: ${colors.white};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  max-width: 384px;
  margin: 32px auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const ColorBox = styled.div`
  ${ColorSmallBox}
`;

export const SuccessHeading = styled.h6`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.darkGrey};
  margin: 20px 0 8px;
`;

export const SuccessParagraph = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.midGrey};
  margin: 0 0 24px;
`;

const styles: any = {
  container: { textAlign: 'center', padding: '10px' },
  downloadLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  button: {
    cursor: 'pointer',
    backgroundColor: 'green',
    color: 'white',
    padding: '15px',
    fontSize: '25px',
    border: 'none',
    borderRadius: '10px',
    marginRight: 10,
  },
  loader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  videoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  video: {
    borderRadius: '50%',
    height: '300px',
    width: '300px',
    radius: '50%',
    transform: 'scaleX(-1)',
  },
  canvas: {
    borderRadius: '50%',
  },
  red: { color: 'red' },
  green: { color: 'green' },
  status: {
    position: 'absolute',
    top: 12,
    right: 20,
    backgroundColor: 'grey',
    padding: 8,
    boxShadow: '2px 2px black',
    fontFamily: 'monospace',
  },
  log: { borderTop: '1px solid #eaeaea', padding: 8 },
  performance: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    padding: 8,
    fontSize: '0.8rem',
    fontFamily: 'monospace',
  },
  Heading: {
    color: '#fff',
  },
  text: {
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    textTransform: 'uppercase',
    fontSize: '12px',
  },
};

export const customStyles = {
  overlay: {
    background: 'rgba(55, 65, 81, 0.8)',
    zIndex: 20,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 512,
    background: '#fff',
    boxShadow:
      '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    border: 'none',
    padding: 24,
  },
};
export const ModalContent = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
export default styles;
