import styled from 'styled-components';
import { colors } from '../../theme';

export const FooterRow = styled.footer`
  padding: 32px 0;
  position: fixed;
  bottom: 0;
  right: 70px;
  @media only screen and (max-width: 767px) {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const FooterText = styled.p`
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${colors.white};
  margin: 0;
  display: flex;
  align-items: center;
  img {
    margin-left: 4px;
    width: 71px;
    height: 17px;
  }
`;

export const Logo = styled.img``;
