import { FooterRow, FooterText, Logo } from './styles';
import LogoIcon from '../../assets/footer_logo.svg';

export const Footer = () => {
  return (
    <FooterRow>
      <FooterText>
        Powered by <Logo src={LogoIcon}></Logo>
      </FooterText>
    </FooterRow>
  );
};
