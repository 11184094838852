import styled from 'styled-components';
import { ReactComponent as LoaderIcon } from '../../assets/loader.svg';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
const Text = styled.p`
  color: white;
  font-family: 'Inter', sans-serif;
  text-align: center;
  padding: 0 10px;
  font-size: 1.15rem;
`;
const Icon = styled(LoaderIcon)`
  margin: 0 !important;
`;

export const Loader = ({ message }) => {
  return (
    <Container>
      <Icon />
      {message && <Text>{message}</Text>}
    </Container>
  );
};
