import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isMobileDevice } from './checkDevice';
import DesktopMessage from '../component/DeviceHandler/DesktopMessage';

const checkAuth = () => {
  if (localStorage.getItem('token')) {
    return true;
  }
  localStorage.clear();
  return false;
};

export const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return checkAuth() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/user-login',
            }}
          />
        );
      }}
    />
  );
};

export const DeviceRoute = ({ componnet: Component, ...rest }) => {
  const isChromeOniOS = /CriOS/i.test(navigator.userAgent);
  if (!isMobileDevice || isChromeOniOS)
    return <DesktopMessage isChromeOniOS={isChromeOniOS} />;
  return (
    <Route
      {...rest}
      render={(props) => {
        <Component {...props} />;
      }}
    />
  );
};
