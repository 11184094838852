/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useState } from 'react';
import { S3ParamsType, S3Upload } from '../../../recording/s3upload';
import apiClient from '../../../network/apiClient';
import { submitLogoImg } from '../../../config/constants';

const useSubmitLogo = (): {
  isSaved: boolean;
  uploading: boolean;
  error: string;
  saveLogo: (params: S3ParamsType, color: string) => Promise<void>;
  resetSaveLogoStates: () => void;
} => {
  const [uploading, setUploading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [error, setError] = useState('');
  const saveLogo = useCallback(async (params: S3ParamsType, color: string) => {
    try {
      setUploading(true);
      const userId = JSON.parse(localStorage.getItem('userLogin') || '');
      const userToken = localStorage.getItem('token') || '';
      // FOR DEBUG IN STAGE
      const response = await S3Upload(params);
      const body = {
        color: color,
        logo: response.file_name,
      };
      await apiClient.put(submitLogoImg(userId.brand_id), body, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response) {
        setIsSaved(true);
        setUploading(false);
      }
    } catch (error: any) {
      setError(error?.message || 'Video submition failed.');
    }
  }, []);
  const resetSaveLogoStates = () => {
    setUploading(false);
    setIsSaved(false);
    setError('');
  };
  return {
    isSaved,
    uploading,
    error,
    saveLogo,
    resetSaveLogoStates,
  };
};
export default useSubmitLogo;
