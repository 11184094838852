import React from 'react';
import { Container } from '../../component/Container';
import { HeaderLogoColumn, Heading1 } from './styles';
import UserLogo from '../UserLogo';
import { Paragraph } from '../Paragraph';

type Props = {
  children: React.ReactNode;
  title: string;
  description?: string;
};

const Layout: React.FC<Props> = ({ children, title, description }) => {
  return (
    <Container>
      <HeaderLogoColumn>
        <UserLogo />
      </HeaderLogoColumn>
      <Heading1>{title}</Heading1>
      {description && <Paragraph>{description}</Paragraph>}
      {children}
    </Container>
  );
};

export default Layout;
