import { Link, useHistory } from 'react-router-dom';
import { navigations } from '../../../utils/globalFunction';
import AddVideoButton from './AddVideoButton';
import Logo from '../../../assets/dashboard_logo.svg';
import LogOutIcon from '../../../assets/logout.svg';
import {
  DropDown,
  Header,
  HeaderBottom,
  HeaderTop,
  LeftCol,
  LogOut,
  MenuList,
  NavCol,
  NavigationCol,
  RightCol,
} from './styles';

const DashboardHeader = ({ modalIsOpen, getBrandVideoDetails }) => {
  const history = useHistory();
  const path = history.location.pathname;
  const signOut = () => {
    localStorage.clear();
    window.location.pathname = '/user-login';
  };

  return (
    <Header>
      <HeaderTop>
        <LeftCol>
          <Link to="/dashboard">
            <img src={Logo} alt="logo" />
          </Link>
        </LeftCol>
        <RightCol>
          <DropDown onClick={signOut}>
            <LogOut src={LogOutIcon} />
            Logout
          </DropDown>
        </RightCol>
      </HeaderTop>
      <HeaderBottom>
        <NavigationCol>
          <NavCol>
            {navigations &&
              navigations.map((data) => {
                return (
                  <MenuList key={data.id}>
                    <Link
                      to={data.link}
                      className={path === data.link ? 'active' : ''}
                    >
                      {data.name}
                    </Link>
                  </MenuList>
                );
              })}
          </NavCol>
        </NavigationCol>
        {path === '/dashboard' && (
          <AddVideoButton
            modalIsOpen={modalIsOpen}
            getBrandVideoDetails={getBrandVideoDetails}
          />
        )}
      </HeaderBottom>
    </Header>
  );
};

export default DashboardHeader;
