import React, { useRef, useState } from 'react';
import {
  BrandVideos,
  PlayIconStyled,
  VideoCol,
  VideoName,
  VideoThumbnail,
} from './styles';

interface Item {
  id: string;
  cover_image: string;
  video_url: string;
  title: string;
}

type Props = {
  item: Item;
  selectedVideo: string;
  setSelectedVideo: (id: string) => void;
};

const Video: React.FC<Props> = ({ item, selectedVideo, setSelectedVideo }) => {
  const ref = useRef<null | HTMLVideoElement>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const viewVideo = () => {
    if (!ref.current) return;
    ref.current.play();
    setIsVideoPlaying(true);
    ref.current.onpause = () => {
      setIsVideoPlaying(false);
    };
  };
  return (
    <VideoCol className="react-player">
      <VideoThumbnail
        data-selected={item.id === selectedVideo}
        onClick={() => setSelectedVideo(item.id)}
      >
        <BrandVideos
          id="clip"
          ref={ref}
          preload="auto"
          data-setup="{}"
          controls={isVideoPlaying}
          poster={item.cover_image}
        >
          <source src={item.video_url} type="video/mp4" />
        </BrandVideos>
        {!isVideoPlaying && <PlayIconStyled onClick={() => viewVideo()} />}
      </VideoThumbnail>
      <VideoName>{item.title}</VideoName>
    </VideoCol>
  );
};

export default Video;
