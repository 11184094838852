import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Input from '../component/Form/Input';
import Label from '../component/Form/Label';
import Error from '../component/Error';
import UserLogo from '../component/UserLogo';
import { ValidateEmail, ValidateName } from '../utils/globalFunction';
import { ContinueButton } from '../component/ContinueButton';
import { Footer } from '../component/Footer';
import ArrowWhite from '../assets/arrow_next_white.svg';

import {
  ArrowIcon,
  CaptchaColumn,
  CheckboxText,
  ConfirmationScreen,
  FormContainer,
  FormSubmissionError,
  HeaderLogoColumn,
  Heading1,
  InputRow,
  Loader,
  LoginContainer,
  MailIcon,
} from './styles';
import './style.css';
import EmailIcon from '../assets/mail_icon.svg';
import useLogin from './useLogin';
import { Paragraph } from '../component/Paragraph';

const defaultFormState = {
  firstName: '',
  lastName: '',
  email: '',
  termAndCondition: false,
  captcha: '',
};

const Login = (props) => {
  const [formState, setFormState] = useState(defaultFormState);
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const { isSubmited, apiError, login, setApiError } = useLogin(setLoader);
  const [captchaValue, setCaptchaValue] = useState('');
  const recaptchaRef = React.useRef();
  const getTC = localStorage.getItem('termCondition');

  useEffect(() => {
    if (isSubmited) {
      setLoader(false);
    }
  }, [isSubmited]);

  useEffect(() => {
    if (!props.location?.state?.brandId) {
      props.history.push('/');
    }
  }, [props.location?.state?.brandId]);

  useEffect(() => {
    if (!apiError) {
      return;
    }
    const timerId = setTimeout(() => setApiError(null), 5000);
    return () => clearTimeout(timerId);
  }, [apiError]);

  const validateForm = () => {
    const errors = {};
    const { firstName, lastName, email, termAndCondition } = formState;
    let error = ValidateName(firstName);
    if (error) {
      errors.firstName = 'The First name ' + error;
    }

    error = ValidateName(lastName);
    if (error) {
      errors.lastName = 'The Last name ' + error;
    }
    if (!ValidateEmail(email)) {
      errors.email = 'The email provided is invalid.';
    }
    if (!termAndCondition) {
      errors.termAndCondition = 'Terms and Conditions need to be accepted';
    }
    setErrors(errors);
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      captchaValue === '' ||
      captchaValue !== recaptchaRef.current.getValue()
    ) {
      setErrors((prevState) => ({
        ...prevState,
        captcha: 'Check the required options.',
      }));
      return;
    }
    const errors = validateForm();
    if (Object.keys(errors).length) {
      return;
    }
    setLoader(true);
    login({ ...formState, brandId: props.location?.state?.brandId });
  };

  const validateEmail = (email) => {
    errors.email = '';
    if (!ValidateEmail(email)) {
      errors.email = 'The email provided is invalid.';
    }
    setErrors(errors);
  };

  const handleChange = ({ target: { name, value } }) => {
    if (value.indexOf(' ') >= 0) {
      return;
    }
    setFormState((prevState) => ({ ...prevState, [name]: value }));
    if (name === 'email') {
      validateEmail(value);
      return;
    }
    errors[name] = '';
    if (name === 'firstName') {
      const error = ValidateName(value);
      if (error) {
        errors.firstName = 'The first name ' + error;
      }
    }
    if (name === 'lastName') {
      const error = ValidateName(value);
      if (error) {
        errors.lastName = 'The last name ' + error;
      }
    }
    setErrors(errors);
  };

  const onCheckboxChangeHandler = ({ target: { checked } }) => {
    setFormState((prevState) => ({ ...prevState, termAndCondition: checked }));
    errors.termAndCondition = checked
      ? ''
      : 'Terms and Conditions need to be accepted';
    setErrors(errors);
  };

  const onChange = (value) => {
    setCaptchaValue(value);
    setErrors((prevState) => ({
      ...prevState,
      captcha: '',
    }));
  };

  return (
    <LoginContainer>
      {loader && <Loader />}
      <HeaderLogoColumn>
        <UserLogo />
      </HeaderLogoColumn>
      {!isSubmited ? (
        <>
          <Heading1>Just 2 steps to go</Heading1>
          <Paragraph>
            So we can send you your video, complete the form and look out for an
            email so you can confirm you are who you are. Then there’s just one
            step to go!!!
          </Paragraph>
          <FormContainer>
            <form onSubmit={handleSubmit} id={'loginForm'}>
              {apiError && (
                <FormSubmissionError>{apiError}</FormSubmissionError>
              )}
              <InputRow className={errors.firstName ? 'error' : ''}>
                <Label>First Name</Label>
                <Input
                  type="text"
                  name="firstName"
                  placeholder="Joe"
                  onChange={handleChange}
                  value={formState.firstName}
                  className="form-control"
                />
                <Error touched={true} message={errors.firstName} />
              </InputRow>
              <InputRow className={errors.lastName ? 'error' : ''}>
                <Label>Last name</Label>
                <Input
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                  value={formState.lastName}
                  placeholder="Doe"
                  className="form-control"
                />
                <Error touched={true} message={errors.lastName} />
              </InputRow>
              <InputRow className={errors.email ? 'error' : ''}>
                <Label>Email</Label>
                <Input
                  type="text"
                  name="email"
                  onChange={handleChange}
                  value={formState.email}
                  placeholder="you@joedoe.com"
                  className="form-control"
                />
                <Error touched={true} message={errors.email} />
              </InputRow>
              <CaptchaColumn>
                <ReCAPTCHA
                  className="captcha-column"
                  sitekey="6LcIqucfAAAAAL4d7Ggb_AV7AswBs11c-UwD9BYz"
                  onChange={onChange}
                  ref={recaptchaRef}
                />
                <Error
                  className="captcha-error"
                  touched={true}
                  message={errors.captcha}
                />
              </CaptchaColumn>
              <InputRow className="checkbox-container input-row">
                <Label className="checkbox-lable">
                  <CheckboxText>
                    In exchange for your free video, we will add you to our
                    marketing comms. For our terms and conditions, privacy
                    policy and details on how you can manage your preferences,{' '}
                    <Link
                      to="route"
                      onClick={(event) => {
                        event.preventDefault();
                        window.open(getTC);
                      }}
                    >
                      click here
                    </Link>
                  </CheckboxText>
                  <input
                    checked={formState.termAndCondition}
                    type="checkbox"
                    onChange={onCheckboxChangeHandler}
                  />
                  <span className="checkmark"></span>
                </Label>
                <Error touched={true} message={errors.termAndCondition} />
              </InputRow>
              <InputRow className="input-row">
                <ContinueButton maxWidth="100%" onClick={validateForm}>
                  Continue
                  <ArrowIcon src={ArrowWhite} />
                </ContinueButton>
              </InputRow>
            </form>
          </FormContainer>
        </>
      ) : (
        <>
          <ConfirmationScreen>
            <Heading1>Confirm your email</Heading1>
            <Paragraph>
              We have sent you a link to confirm your email. Click on it to
              continue.
              <br />
              <br />
              Occasionally emails can drop into your junk folder so please keep
              an eye out.
            </Paragraph>
            <MailIcon src={EmailIcon} />
          </ConfirmationScreen>
        </>
      )}
      <Footer />
    </LoginContainer>
  );
};

export default Login;
