import styled from 'styled-components';
import { colors } from '../../../theme';

export const FooterCol = styled.div`
  display: flex;
  margin-top: 76px;
`;

export const ModalCancel = styled.div`
  width: 50%;
  background: ${(props) => props.background || colors.offWhite}};
  border: 1px solid ${(props) => props.border || colors.borderColor};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.color || colors.darkGreyText}};
  text-align: center;
  padding: 8px 9px;
  cursor: pointer;
  &:first-child {
    margin-right: 12px;
  }
`;
export const SelectFileCol = styled.div`
  text-align: center;
  border: 2px dashed ${colors.videoBorder};
  min-height: 216px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colors.lightGray};
`;

export const FotterContent = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${colors.darkGrey};
  margin: 24px 0 0;
`;

export const VideoIcon = styled.img``;

export const VideoText = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.darkGrey};
  margin: 20px 0;
  font-weight: 500;
`;

export const VideoFormatText = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.subParagraph};
`;

export const VideoUploadCol = styled.div``;

export const DragText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.midGrey};
  margin-left: 12px;
`;
