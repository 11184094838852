import { useHistory } from 'react-router';
import TickImg from '../../../assets/tick_icon.svg';
import {
  Heading4,
  Paragraph2,
  ThemeBackground,
  TickIcon,
  WelcomeConatiner,
  WelcomeHeader,
  WhiteBox,
} from './styles';

const SubmitVideos = () => {
  const history = useHistory();
  const goBack = () => {
    history.push('/');
  };

  return (
    <ThemeBackground>
      <WelcomeConatiner>
        <WelcomeHeader>
          <img
            alt=""
            src="/static/media/footer_logo.9ba6617351afd185cbd7e0b73c25ace4.svg"
          />
          <br /> <br /> <br />
        </WelcomeHeader>
        <WhiteBox>
          <TickIcon src={TickImg} />
          <Heading4>Process complete!</Heading4>
          <Paragraph2>
            Thanks, your video will now be processed and sent to you by email.
            You can typically expect to receive it within 60-90 minutes.
            <br />
            <br />
            Occasionally emails can drop into your junk folder so please keep an
            eye out.
          </Paragraph2>
        </WhiteBox>
      </WelcomeConatiner>
    </ThemeBackground>
  );
};

export default SubmitVideos;
