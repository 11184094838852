import React, { useEffect, useState } from 'react';
import {
  getQueryParam,
  ValidateName,
  ValidatePassword,
} from '../../utils/globalFunction';
import Input from '../../component/Form/Input';
import { Loader } from '../../component/Loader';
import Label from '../../component/Form/Label/index';
import Error from '../../component/Error';
import useUserLogin from './useUserLogin';
import Logo from '../../assets/chose2_logo.svg';
import {
  ContinueButton,
  ForgotButton,
  FormContainer,
  FormSubmissionError,
  Heading,
  InputRow,
  LoginHeader,
  UserSection,
} from './styles';

const UserLogin = (props) => {
  const getUserName = getQueryParam(props.location.search, 'username');
  const defaultStates = {
    userName: getUserName || '',
    password: '',
  };
  const [formState, setFormState] = useState(defaultStates);
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const { isSubmited, apiError, login } = useUserLogin(setLoader);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('token');
    const getStoredUserName = JSON.parse(
      localStorage.getItem('userLogin')
    )?.username;
    if (isAuthenticated && getUserName === getStoredUserName) {
      window.location.pathname = '/dashboard';
    }
  }, []);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('token');
    if (isSubmited && isAuthenticated) {
      setFormState(defaultStates);
      window.location.pathname = '/dashboard';
      setLoader(false);
    }
  }, [isSubmited]);

  const validateForm = () => {
    const errors = {};
    const { userName, password } = formState;
    let error = ValidateName(userName);
    if (error) {
      errors.userName = 'The user name ' + error;
    }
    error = ValidatePassword(password);
    if (error) {
      errors.password = 'The password ' + error;
    }
    setErrors(errors);
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length) {
      return;
    }
    setLoader(true);
    login({ ...formState });
  };

  const handleChange = ({ target: { name, value } }) => {
    if (value.indexOf(' ') >= 0) {
      return;
    }
    setFormState((prevState) => ({ ...prevState, [name]: value }));
    errors[name] = '';
    if (name === 'userName') {
      const error = ValidateName(value);
      if (error) {
        errors.userName = 'The user name ' + error;
      }
    }
    if (name === 'password') {
      const error = ValidatePassword(value);
      if (error) {
        errors.password = 'The password ' + error;
      }
    }
    setErrors(errors);
  };
  const mailLink = `mailto:${process.env.REACT_APP_EMAIL}`;
  return (
    <UserSection>
      {loader && <Loader />}
      <LoginHeader>
        <img src={Logo} alt="logo" />
        <Heading>Login to your account</Heading>
      </LoginHeader>
      <FormContainer>
        <form onSubmit={handleSubmit} id={'loginForm'}>
          <InputRow className={errors.userName ? 'error' : ''}>
            <Label>Username</Label>
            <Input
              type="text"
              name="userName"
              placeholder="Joe"
              className="form-control"
              value={formState.userName}
              onChange={handleChange}
            />
            <Error touched={true} message={errors.userName} />
          </InputRow>
          <InputRow className={errors.password ? 'error' : ''}>
            <Label>Password</Label>
            <Input
              type="password"
              name="password"
              placeholder="•••••••••••"
              className="form-control"
              value={formState.password}
              onChange={handleChange}
            />
            <Error touched={true} message={errors.password} />
          </InputRow>
          <InputRow>
            <ContinueButton onClick={() => validateForm()}>
              Login
            </ContinueButton>
          </InputRow>
          {apiError && <FormSubmissionError>{apiError}</FormSubmissionError>}
        </form>
        <ForgotButton>
          <a href={mailLink}>Forgot password?</a>
        </ForgotButton>
      </FormContainer>
    </UserSection>
  );
};

export default UserLogin;
