export const colors = {
  white: '#ffffff',
  black: '#000000',
  darkGrey: '#111827',
  darkGreyText: '#374151',
  midGrey: '#4B5563',
  borderColor: '#D1D5DB',
  lightGray: '#F9FAFB',
  errorRed: '#7F1D1D',
  red: '#EF4444',
  errorBorder: '#FCA5A5',
  greenColor: '#10B981',
  redColor: '#DC2626',
  subParagraph: '#6B7280',
  red100: '#FEE2E2',
  navColor: '#E5E7EB',
  lightGreen: '#d1fae5',
  darkGreen: '#065F46',
  lightYellow: '#FEF3C7',
  darkYellow: '#92400E',
  lightBlue: '#E0E7FF',
  darkBlue: '#3730A3',
  lightRed: '#f7b4b4',
  offWhite: '#f3f4f6',
  tabHover: '#1f2937',
  videoBorder: '#9ca3af',
  inputBorder: '#cfd3d9',
};

export const fontFamily = {
  default: 'Inter, sans-serif',
};

export const InputFieldStyle = {
  color: colors.darkGrey,
  padding: '14px 16px',
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: 400,
  borderColor: colors.midGrey,
  cursor: 'pointer',
};

export const ColorSmallBox = {
  background: colors.greenColor,
  width: 48,
  height: 48,
  borderRadius: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
