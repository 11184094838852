/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useState } from 'react';
import { S3ParamsType, S3Upload } from './s3upload';
import apiClient from '../network/apiClient';
import { submitUserVideo } from '../config/constants';

const useSubmitVideo = (): {
  isSaved: boolean;
  uploading: boolean;
  error: string;
  saveVideo: (
    params: S3ParamsType,
    videoId: string,
    userId: string
  ) => Promise<boolean>;
  resetSaveVideoStates: () => void;
} => {
  const [uploading, setUploading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [error, setError] = useState('');
  const saveVideo = async (
    params: S3ParamsType,
    videoId: string
  ): Promise<boolean> => {
    try {
      setUploading(true);
      const userId = JSON.parse(localStorage.getItem('user') || '{}');
      if (!userId.id) {
        setError('No user data found!');
        return false;
      }
      // FOR DEBUG IN STAGE
      const response = await S3Upload(params);
      const body = {
        brand_video_id: videoId,
        face_video: response.file_name,
      };
      await apiClient.put(submitUserVideo(userId.id), body);
      if (response) {
        setIsSaved(true);
        setUploading(false);
        return true;
      }
      return false;
    } catch (error: any) {
      setError(error?.message || 'Video submition failed.');
      return false;
    }
  };
  const resetSaveVideoStates = () => {
    setUploading(false);
    setIsSaved(false);
    setError('');
  };
  return {
    isSaved,
    uploading,
    error,
    saveVideo,
    resetSaveVideoStates,
  };
};
export default useSubmitVideo;
