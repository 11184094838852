import styled from 'styled-components';
import { colors } from '../../theme';
import LeftArrow from '../../assets/arrow-left.svg';

export const DashboardBody = styled.section`
  & .pagination-col {
    padding: 0;
    margin: 32px 0 0;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & li a {
      cursor: pointer;
      padding: 6px 12px;
      border-radius: 4px;
      display: flex;
    }
    & .previous a {
      width: 12px;
      height: 12px;
      background: url(${LeftArrow}) no-repeat center center;
    }
    & .next a {
      width: 12px;
      height: 12px;
      background: url(${LeftArrow}) no-repeat center center;
      transform: rotate(180deg);
    }
    & .selected a {
      background: ${colors.darkGrey};
      color: white;
    }
    & .disabled a {
      cursor: not-allowed;
    }
  }
`;

export const customStyles = {
  overlay: {
    background: 'rgba(55, 65, 81, 0.8)',
    zIndex: 20,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 512,
    background: '#fff',
    boxShadow:
      '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    border: 'none',
    padding: 24,
  },
};

export const editVideoModalStyles = {
  overlay: {
    background: 'rgba(55, 65, 81, 0.8)',
    zIndex: 20,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 981,
    background: '#fff',
    boxShadow:
      '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    border: 'none',
    padding: 0,
  },
};
