import React, { useEffect, useRef, useState } from 'react';
import Human from '@vladmandic/human/dist/human.esm';
import RecordRTC from 'recordrtc';
import { FaceRecordView } from '../recording/styles';

const videoMimeType = 'video/webm;codecs=h264';

const config = {
  debug: true,
  modelBasePath: 'https://cdn.jsdelivr.net/npm/@vladmandic/human/models',
  face: {
    enabled: true,
    mesh: { enabled: true },
    iris: { enabled: true },
    description: { enabled: false },
    emotion: { enabled: true },
    detector: { rotation: true, return: true },
  },
  body: { enabled: false },
  hand: { enabled: false },
  object: { enabled: false },
};
const videoWidth = 300;
const videoHeight = 300;

let rtcRecorder = null;
const FaceRecording = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const videoRef = useRef();
  const human = new Human(config);
  human.env['perfadd'] = false;
  useEffect(() => {
    const loadModels = async () => {
      await human.load();
      human
        .warmup()
        .then(() => {
          console.log('warm up ready...');
        })
        .catch((error) => console.error('warpup error: ', error));
    };
    try {
      loadModels();
    } catch (error) {
      console.error({ foundError: error });
    }
  }, []);

  useEffect(() => {
    async function startStream() {
      // if (!isCameraOpen) return;
      if (!videoRef.current) return;
      console.log('video ref available', videoRef.current);
      if (!rtcRecorder) {
        await initWebCamera();
      }
      const video = videoRef.current;
      try {
        await video.play();
      } catch (error) {
        alert(error);
      }
      // if (canvasRef.current) {
      //   canvasRef.current.width = videoWidth;
      //   canvasRef.current.height = videoHeight;
      // }
      const track = video.srcObject.getVideoTracks()[0];
      const capabilities = track.getCapabilities ? track.getCapabilities() : '';
      const settings = track.getSettings ? track.getSettings() : '';
      const constraints = track.getConstraints ? track.getConstraints() : '';
      console.log('video:', video.videoWidth, video.videoHeight, track.label, {
        stream: video.srcObject,
        track,
        settings,
        constraints,
        capabilities,
      });
      // await detectionLoop();
      // if (enableCanvas) {
      //   // await drawLoop();
      // }
      // resetVideoMatrics();
    }
    startStream();
  }, [videoRef]);

  const initWebCamera = async () => {
    const options = {
      audio: false,
      video: {
        facingMode: 'user',
        width: { min: 300 },
        height: { min: 300 },
      },
    };
    let stream = null;
    try {
      stream = await navigator.mediaDevices.getUserMedia(options);
      if (!stream) {
        return;
      }
      options.video.height = { min: videoHeight };
      options.video.width = { min: videoWidth };
      stream = await navigator.mediaDevices.getUserMedia(options);
      console.log('stream', stream);
      const video = videoRef.current;
      video.srcObject = stream;
      rtcRecorder = new RecordRTC(stream, {
        type: 'video',
        mimeType: videoMimeType,
      });
    } catch (error) {
      console.log({ error });
      let errorMsg = 'There is something wrong with camera access.';
      if (error?.name === 'OverconstrainedError') {
        errorMsg = 'Video resolution is less than 1280x720.';
      }
      if (error?.name === 'NotAllowedError') {
        errorMsg = 'Please allow to access your camera.';
      }
      setError({
        resolutionError: errorMsg,
      });
      alert(errorMsg);
    }
  };

  const stopRecording = async () => {
    setLoading(true);
    await rtcRecorder?.stopRecording(async () => {
      const blob = await rtcRecorder?.getBlob();
      if (!blob) {
        return;
      }
      const videoLocal = URL.createObjectURL(blob);

      var link = document.createElement('a');
      link.download = 'filename.mp4';
      link.href = videoLocal;
      link.click();
    });
    setLoading(false);
  };
  const startRecording = async () => {
    await rtcRecorder?.startRecording();
  };
  if (loading) {
    return <p style={{ color: '#fff' }}>Face Recording</p>;
  }

  return (
    <div>
      {error && error.resolutionError}
      <p style={{ color: '#fff' }}>Face Recording</p>
      <FaceRecordView ref={videoRef} playsInline />
      <button onClick={startRecording}>Start Recording</button>
      <button onClick={stopRecording}>Finish Recording</button>
    </div>
  );
};

export default FaceRecording;
