import { useRef } from 'react';

export const videoMessage = [
  'Close your Mouth',
  'Open your mouth',
  'Slowly move your head to the left',
  'Slowly move your head to the right',
  'Slowly move your head up',
  'Slowly  move your head down',
  'Make a full circle with your head',
  'Make a big smile',
  'Make a frown',
  'Make an angry face',
  'Scan complete.',
];

const useVideoRecordingSteps = () => {
  const step = useRef(0);

  const nextStep = (value: number) => {
    step.current = value;
  };
  return { step: videoMessage[step.current], nextStep };
};

export default useVideoRecordingSteps;
