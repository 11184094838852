import React from 'react';
import styled from 'styled-components';
import { CTAButton } from '../../styles';

const ButtonWithText = styled.button`
  background: transparent;
  width: 70%;
  color: red;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
`;
const FlexBox = styled.div`
  display: flex;
`;

const ConfirmStepRecording = ({ accept, reject }) => {
  return (
    <div>
      <p>Did you capture everything from the instruction video?</p>
      <FlexBox>
        <ButtonWithText onClick={reject}>No, try again</ButtonWithText>
        <CTAButton onClick={accept}>Yes, continue</CTAButton>
      </FlexBox>
    </div>
  );
};

export default ConfirmStepRecording;
