import { useState } from 'react';
import apiClient from '../../network/apiClient';
import { getBrandVideoDetailsApi } from '../../config/constants';

const useBrandVideoDetail = (getToken, setLoader) => {
  const [brandVideoDetail, setBrandVideoDetail] = useState(null);
  const getBrandVideoDetails = async (pageNumber) => {
    try {
      const param = pageNumber ? `?page=${pageNumber}` : '';
      const apiResponse = await apiClient.get(
        `${getBrandVideoDetailsApi}${param}`,
        {
          headers: { Authorization: `Bearer ${getToken}` },
        }
      );
      setBrandVideoDetail(apiResponse.data);
      setLoader(false);
    } catch (error) {
      console.log(error, 'ERROR');
      setLoader(false);
    }
  };

  return {
    getBrandVideoDetails,
    brandVideoDetail,
  };
};
export default useBrandVideoDetail;
