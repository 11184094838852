import React from 'react';
import Layout from '../../component/Layout/Index';
import { Footer } from '../../component/Footer';
import ThankYou from '../../recording/ThankYou';

const ThankyouPage = () => {
  return (
    <Layout
      title="Process Complete!"
      description="Thanks, Your video will now be processed and sent to you by email. You can typically expect to receive it within 60-90 minutes."
    >
      <ThankYou />
      <Footer />
    </Layout>
  );
};

export default ThankyouPage;
