export const isProdEnv = window.location.href.includes(
  'chose2-react-prod.herokuapp'
);
export const apiURL = isProdEnv
  ? 'https://chose2-api-prod.herokuapp.com/api/v1'
  : 'https://chose2-api-staging.herokuapp.com/api/v1';
export const siteURL = isProdEnv
  ? 'https://chose2-react-prod.herokuapp.com'
  : 'https://chose2-react.herokuapp.com';
import VideoImage from '../assets/video-img.jpg';
import VideoImage2 from '../assets/video-img2.jpg';
import VideoImage3 from '../assets/video-img3.jpg';
import VideoImage4 from '../assets/video-img4.jpg';

export const loginApi = `${apiURL}/users`;
export const brandVideosApi = `${apiURL}/brand-videos/public`;
export const getPresignedUrlApi = `${apiURL}/presigned-put-url`;
export const userLoginApi = `${apiURL}/login`;
export const userBrandVideoUploadApi = `${apiURL}/brand-videos`;
export const userBrandVideoEditApi = (brandVideoId) =>
  `${apiURL}/brand-videos/${brandVideoId}/update`;
export const submitUserVideo = (userId) =>
  `${apiURL}/users/${userId}/face-video`;
export const submitLogoImg = (userId) => `${apiURL}/clients/${userId}/update`;
export const getClientDetails = `${apiURL}/client-details`;
export const getBrandVideoDetailsApi = `${apiURL}/brand-videos`;
export const checkRecordedVideos = (token, brandId) =>
  `${apiURL}/users/${token}/existing-video?brand_video_id=${brandId}&token=${token}`;
export const manageQuoteApi = (userId) =>
  `${apiURL}/users/${userId}/manage-quote`;
export const videoData = [
  {
    videoLink: VideoImage,
    videoHeading: 'Cristiano Ronaldo CR7',
  },
  {
    videoLink: VideoImage2,
    videoHeading: 'Paul Pogba',
  },
  {
    videoLink: VideoImage3,
    videoHeading: 'Harry Maguire',
  },
  {
    videoLink: VideoImage4,
    videoHeading: 'Mason Greenwood',
  },
];
