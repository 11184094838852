import styled from 'styled-components';
import { colors } from '../../../theme';

export const Header = styled.header`
  background: ${colors.darkGrey};
  padding: 20px 70px;
  @media only screen and (max-width: 1024px) {
    padding: 20px 30px;
  }
`;

export const HeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.darkGreyText};
`;

export const LeftCol = styled.div``;

export const RightCol = styled.div`
  display: flex;
  align-items: center;
`;

export const DropDown = styled.div`
  margin-left: 24px;
  background: ${colors.white};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.darkGrey};
  font-size: 14px;
  cursor: pointer;
  padding: 9px 17px 9px 12px;
  font-weight: 500;
`;

export const HeaderBottom = styled.div`
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const NavigationCol = styled.div``;

export const NavCol = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
`;

export const MenuList = styled.li`
  list-style: none;
  margin-right: 28px;
  a {
    color: ${colors.navColor};
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 12px;
    transition: all 0.3s ease-in-out 0s;
    &:hover {
      background: ${colors.white};
      color: ${colors.darkGrey};
      border-radius: 4px;
    }
  }
  .active {
    background: ${colors.white};
    color: ${colors.darkGrey};
    border-radius: 4px;
  }
`;

export const LogOut = styled.img`
  width: 16px;
  margin-right: 10px;
  margin-top: 1px;
`;
