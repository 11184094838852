import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const Label = ({ children, labelFor, className }) => {
  return (
    <label className={className || ''} htmlFor={labelFor}>
      {children}
    </label>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  labelFor: PropTypes.string,
  className: PropTypes.string,
};

export default Label;
