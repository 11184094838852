import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Loader } from '../component/Loader';
import { manageQuoteApi, submitUserVideo } from '../config/constants';
import ConfirmStepRecording from './components/ConfirmStepRecording';
import useCountDown from './components/CountDown';
import RecordButton from './components/RecordButton';
import TutorialVideo from './components/TutorialVideos';
import UserStream from './components/UserStream';
import { ThemeBackground, WelcomeConatiner } from './styles';
import apiClient from '../network/apiClient';

const VIDEO_LOOPS = 4;

const CustomThemeBackground = styled(ThemeBackground)`
  padding: 32px 24px 24px 24px;
`;

const PageContainer = styled.div`
  color: white;
  width: 400px;
  @media only screen and (max-width: 767px) {
    width: 300px;
    height: 450px;
  }
`;
export const Container = styled.div`
  position: absolute;
  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
  }
`;
const VideoCapture = styled.div`
  position: relative;
  width: 400px;
  height: 600px;
  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
  }
`;
export const InstructionListItem = styled.p`
  display: flex;
  align-items: center;
  opacity: 0.8;
  font-size: 0.9rem;
  font-weight: 700;
`;
export const VideoContainer = styled.div`
  width: 400px;
  height: 600px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.04);
  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
  }
`;
export const TutorialVideoContainer = styled.div`
  width: 220px;
  height: 124px;
  position: absolute;
  border-radius: 16px;
  top: 2px;
  right: 2px;
  z-index: 2;
`;
const RecordButtonContainer = styled.div`
  position: absolute;
  bottom: 45px;
  left: 170px;
  z-index: 9;
  @media only screen and (max-width: 767px) {
    left: 41%;
  }
`;

const FooterText = styled.div`
  font-size: 0.75rem;
  opacity: 0.5;
  text-transform: uppercase;
`;
const FlexBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 22px;
`;
const TimerText = styled.p`
  text-align: center;
  min-height: 25px;
`;

const VideoCaptureStep2 = ({ nextStep, brandId, userId }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [step, setStep] = useState(0);
  const [stepConfirmScreen, setStepConfirmScreen] = useState(false);
  const [isInstructionsVisible, setIsInstructionsVisible] = useState(false);
  const totalSteps = parseInt(process.env.REACT_APP_TOTAL_STEPS);
  const [timeLeft, setTimeLeft] = useCountDown();
  const [isReviewing, setIsReviewing] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [isRecordDisable, setIsRecordDisable] = useState(true);
  const [loading, setLoading] = useState();
  const [loops, setLoops] = useState(0);

  const startTutorial = () => {
    discardStep();
  };

  const stepRecordingComplete = () => {
    console.log('step complete');
    setIsRecording(false);
    setStepConfirmScreen(true);
    setIsReviewing(true);
  };

  const completeStep = () => {
    if (step < totalSteps) {
      setLoops(0);
      setStep(step + 1);
    }
  };

  const discardStep = () => {
    setStepConfirmScreen(false);
    setIsInstructionsVisible(true);
    setIsReviewing(false);
  };

  const startRecording = () => {
    console.log('recording start');
    setIsRecording(true);
    setIsInstructionsVisible(false);
    setIsRecordDisable(true);
    setTimeLeft(parseInt(process.env.REACT_APP_TIME_PER_STEP));
  };

  const handleLoopComplete = ({ play, pause }) => {
    setLoops((prevState) => {
      const nextLoop = prevState + 1;
      if (nextLoop >= VIDEO_LOOPS) {
        if (typeof pause === 'function') pause();
      } else {
        if (typeof play === 'function') play();
      }

      return nextLoop;
    });
  };

  useEffect(() => {
    if (loops === 1) {
      setIsRecordDisable(false);
    }
  }, [loops]);

  useEffect(() => {
    if (step !== 0) {
      discardStep();
    }

    if (step === totalSteps) {
      setIsDone(true);
    }
  }, [step]);

  useEffect(() => {
    console.log('timeLeft', timeLeft);
    if (timeLeft === 0) {
      stepRecordingComplete();
    }
  }, [timeLeft]);

  const uploadVideos = async (allVideos) => {
    setLoading(true);
    try {
      const randomNumber = Math.floor(Math.random() * 90000) + 10000;
      const date = new Date().getTime();
      const payload = new FormData();
      allVideos.forEach((vid) => {
        payload.append('video', vid);
      });

      if (!userId) {
        return false;
      }
      await apiClient.put(manageQuoteApi(userId), { brand_video_id: brandId });
      const apiUrl = `${process.env.REACT_APP_STITCH_URL}/thumbnail`;
      // const apiUrl = 'http://localhost:5000/thumbnail';
      const fileName = `${date}_${randomNumber}.mp4`;
      payload.append('fileName', fileName);
      payload.append('brandId', brandId);
      payload.append('userId', userId);
      await fetch(apiUrl, {
        method: 'POST',
        body: payload,
      });
      nextStep();
    } catch (e) {
      nextStep(5);
      console.log('Error in uploading videos.', e);
    }
  };

  return (
    <CustomThemeBackground>
      <WelcomeConatiner>
        {loading && (
          <Loader message="Your video is uploading. Please wait for confirmation before closing the screen. On a good Wi-Fi connection, it is likely to take 20-30 seconds. On cellular it may take longer." />
        )}
        <PageContainer>
          <VideoCapture>
            <Container>
              <RecordButtonContainer>
                <RecordButton onClick={startRecording} hide={isRecordDisable} />
              </RecordButtonContainer>
              <VideoContainer>
                <UserStream
                  step={step}
                  play={isRecording}
                  isReviewing={isReviewing}
                  isDone={isDone}
                  startTutorial={startTutorial}
                  uploadVideos={uploadVideos}
                />
              </VideoContainer>
              <TutorialVideoContainer>
                <TutorialVideo
                  step={step}
                  play={isInstructionsVisible}
                  loopComplete={handleLoopComplete}
                  isDone={isDone}
                />
              </TutorialVideoContainer>
            </Container>
          </VideoCapture>
          {stepConfirmScreen && (
            <ConfirmStepRecording
              reject={() => {
                discardStep();
                setLoops(0);
              }}
              accept={() => {
                completeStep();
                setLoops(0);
              }}
            />
          )}
          {!stepConfirmScreen && (
            <div>
              <TimerText>{timeLeft && `00:0${timeLeft}`}</TimerText>
              <InstructionListItem>
                Please copy the head movements in the instruction video.
                <br /> <br />
                Click record when you are ready. Don't worry, if you get it
                wrong you can re-do it.
              </InstructionListItem>
            </div>
          )}
          <FlexBox>
            <FooterText>Chose2</FooterText>
            <FooterText>
              STEP {isDone ? step : step + 1} / {totalSteps}
            </FooterText>
          </FlexBox>
        </PageContainer>
      </WelcomeConatiner>
    </CustomThemeBackground>
  );
};

export default VideoCaptureStep2;
