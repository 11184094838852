import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import './style.css';

const Input = forwardRef((props, ref) => (
  <input
    autoSave="off"
    ref={ref}
    {...props}
    className={props.className || ''}
  />
));

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  selectedValue: PropTypes.string,
};

export default Input;
