import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import IframeCol from './iframe/index';
import VideoMessage from './videoMessage/index';
import Login from './login/index';
import Welcome from './welcomeScreen/index';
import './App.css';
import Recording2 from './recording/index2';
import ThankyouPage from './pages/Thankyou';
import UserLogin from './userAccountApp/UserLogin';
import UserDashboard from './userAccountApp/UserDashboard';
import TestRedux from './userAccountApp/TestRedux';
import Setting from './userAccountApp/Setting';
import { AuthRoute, DeviceRoute } from './utils/middleware';
import { store } from './store/store';
import { Provider } from 'react-redux';
import FaceRecording from './pages/FaceRecording';
import NewRecordingFlow from './recording/NewRecordingFlow';
import NotFound from './userAccountApp/components/NotFound';

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <AuthRoute exact path={'/settings'} component={() => <Setting />} />
          <DeviceRoute path="/recording" component={FaceRecording} />
          <AuthRoute
            exact
            path={'/dashboard'}
            component={() => <UserDashboard />}
          />
          <DeviceRoute exact path={'/test-redux'} component={TestRedux} />
          <Route
            exact
            path={'/user-login/:username?'}
            component={(props) => <UserLogin {...props} />}
          />
          <DeviceRoute exact path={'/Options'} component={VideoMessage} />
          <DeviceRoute exact path={'/login'} component={Login} />
          <DeviceRoute exact path={'/thankyou'} component={ThankyouPage} />
          <DeviceRoute
            exact
            path={'/choose-option'}
            component={NewRecordingFlow}
          />
          <DeviceRoute exact path={'/video'} component={NewRecordingFlow} />
          <DeviceRoute exact path={'/video1'} component={Recording2} />
          <DeviceRoute
            exact
            path={'/videocapture'}
            component={NewRecordingFlow}
          />
          <Route
            exact
            path={'/welcome/:brand_id?'}
            component={(props) => <Welcome {...props} />}
          />
          <Route exact path={'/iframe'} component={() => <IframeCol />} />
          <Route exact path={'/404'} component={NotFound} />
          <Route
            path={'/:brand_id?'}
            component={(props) => <Welcome {...props} />}
          />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
