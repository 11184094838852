import styled from 'styled-components';
import { colors, fontFamily } from '../../theme';

export const UserSection = styled.section`
  height: 100vh;
  background: ${colors.lightGray};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoginHeader = styled.div`
  text-align: center;
  margin-bottom: 32px;
  @media only screen and (max-width: 420px) {
    padding: 0 15px;
  }
`;

export const Heading = styled.h1`
  font-weight: 800;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.darkGrey};
  margin: 24px 0;
`;

export const Paragraph = styled.h1`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4b5563;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 40px;
  width: 448px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  background: ${colors.white};
  @media only screen and (max-width: 767px) {
    width: 100%;
    border-radius: 0;
  }
`;

export const InputRow = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const ContinueButton = styled.button`
  padding: 9px 17px;
  border-radius: 6px;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background: ${colors.darkGrey};
  width: 100%;
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: ${fontFamily.default};
  transition: transform 250ms;
  &:hover {
    transform: translateY(-2px);
    background: #263046;
  }
`;

export const ForgotButton = styled.div`
  margin-top: ${(props) => props.marginTop || '24px'};
  a {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.darkGrey};
  }
`;

export const FormSubmissionError = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  background: rgba(221, 52, 68, 0.2);
  border-radius: 6px;
  padding: 10px;
  text-align: center;
`;
