import styled from 'styled-components';
import { colors } from '../../../theme';

export const LogoUploadSection = styled.div`
  margin-top: 16px;
`;

export const LogoPreviewCol = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const LogoColumn = styled.div`
  width: 100px;
  height: 100px;
`;

export const LogoPreview = styled.img`
  width: 100%;
  object-position: center;
  object-fit: contain;
  height: 100%;
  border-radius: 100%;
`;

export const SelectFileButton = styled.div`
  background: ${colors.navColor};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.darkGrey};
  margin-left: ${(props) => props.marginLeft || '37px'};
  padding: 9px 13px;
  cursor: pointer;
`;

export const LimitText = styled.p`
  margin: 8px 0 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: ${colors.subParagraph};
  padding-left: 20px;
`;
