/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react';
import apiClient from '../../network/apiClient';
import { brandVideosApi } from '../../config/constants';

const useClientDetails = (brandId, setLoader) => {
  const [clientDetail, setClientDetail] = useState(null);
  const getDetails = async () => {
    try {
      if ((clientDetail, !brandId)) {
        return;
      }
      const apiResponse = await apiClient.get(brandVideosApi, {
        params: { uuid: brandId },
      });
      localStorage.setItem('userLogo', apiResponse.data?.logo);
      setClientDetail(apiResponse.data);
      setLoader(false);
    } catch (error) {
      console.log(error, 'ERROR');
    }
  };

  return {
    getDetails,
    clientDetail,
  };
};
export default useClientDetails;
