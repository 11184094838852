import React, { useEffect, useState } from 'react';
import { Loading } from '../../component/common/Loaders/Loading';
import { ReactComponent as PlayIcon } from '../../assets/resume.svg';
import { ReactComponent as DoneIcon } from '../../assets/done.svg';
import styled from 'styled-components';
import { Overlay } from './Indication/style';

const Calculate = styled(Overlay)`
  background: rgba(255, 255, 255, 0.99);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px 0;
  border-radius: 0;
  backdrop-filter: none;
  z-index: 101;
`;

const Icon = styled(PlayIcon)`
  cursor: pointer;
  &:hover {
    drop-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  }
`;
const CurrentStep = styled.span`
  max-width: 40%;
`;
const Contain = styled.div`
  width: 60%;
`;

const Calculating = ({
  onClick,
  calculationSummary,
  step,
  startRecording,
  isDone,
}) => {
  const [loading, setLoading] = useState(true);
  const isRetake = calculationSummary === 'Redo';
  useEffect(() => {
    console.log('loaded');
    setTimeout(() => setLoading(false), 1500);
  }, []);

  useEffect(() => {
    if (!loading && step === 0) {
      startRecording();
    }
  }, [loading]);
  if (loading) {
    return (
      <Calculate>
        <Loading />
      </Calculate>
    );
  }
  return (
    <Calculate>
      <Contain>
        {!isDone && <Icon color="#ff3d00" onClick={onClick} />}
        {isDone && <DoneIcon width={64} color="#ff3d00" onClick={onClick} />}
        <CurrentStep>
          {isRetake && <span>Snap! Let's try with different angles</span>}
          {!isRetake && (
            <span>
              Great! Let's move to next step.
              <br />
            </span>
          )}
          {isDone && 'Scan Complete'}
        </CurrentStep>
      </Contain>
    </Calculate>
  );
};

export default React.memo(Calculating);
