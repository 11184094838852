import React from 'react';
import { useDropzone } from 'react-dropzone';
import Error from '../../../component/Error';
import LogoAvtar from '../../../assets/logo_avtar.svg';
import {
  LimitText,
  LogoColumn,
  LogoPreview,
  LogoPreviewCol,
  LogoUploadSection,
  SelectFileButton,
} from './styles';

const LogoUpload = ({
  setFiles,
  setLogoSizeError,
  logoSizeError,
  files,
  setUploading,
}) => {
  const { getRootProps, acceptedFiles, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.svg'],
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) {
        return;
      }
      const preview = URL.createObjectURL(file);
      const img = new Image();
      img.src = preview;
      img.onload = () => {
        if (img.width > 600 && img.height > 600) {
          setLogoSizeError(
            'The logo that you have uploaded is not the correct size of 600x600 please try again'
          );
          return;
        }
        setLogoSizeError();
      };
      setFiles(
        Object.assign(file, {
          preview,
        })
      );
      setUploading(true);
    },
  });
  return (
    <LogoUploadSection>
      <LogoPreviewCol>
        <LogoColumn>
          <LogoPreview id="avtar" src={files.preview || LogoAvtar} />
        </LogoColumn>
        <SelectFileButton {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          Upload
        </SelectFileButton>
      </LogoPreviewCol>
      <LimitText>600x600px</LimitText>
      <Error touched={true} message={logoSizeError} />
    </LogoUploadSection>
  );
};

export default LogoUpload;
