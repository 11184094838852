import React from 'react';
import { ThemeBackground, WelcomeConatiner } from './styles';

const IframeCol = () => {
  return (
    <ThemeBackground>
      <WelcomeConatiner>
        <iframe
          src="https://chose2-react.herokuapp.com"
          width="100%"
          height="768px"
          allowfullscreen="allowfullscreen"
        ></iframe>
      </WelcomeConatiner>
    </ThemeBackground>
  );
};

export default IframeCol;
