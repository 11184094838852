import React from 'react';
import { ChromePicker } from 'react-color';
import { useClickOutside } from '../../../utils/globalFunction';
import { ColorPickerSection, SelectText } from './styles';

const ColorPicker = ({ bgColor, setBgColor, setShowColorModal }) => {
  const handleChangeComplete = (color) => {
    setBgColor({ background: color.hex });
  };

  const domNode = useClickOutside(() => {
    setShowColorModal(false);
  });

  return (
    <ColorPickerSection ref={domNode}>
      <SelectText>Select colour</SelectText>
      <ChromePicker
        disableAlpha
        color={bgColor?.background}
        onChange={handleChangeComplete}
        width="214px"
        padding="0"
        name={bgColor?.background}
        color={bgColor?.background}
      ></ChromePicker>
    </ColorPickerSection>
  );
};

export default ColorPicker;
