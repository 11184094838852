import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Button = styled.button`
  background: #ef4444;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  border: 5px solid white;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  padding: 0;
`;

const RecordButton = ({ onClick, hide }) => {
  const [counter, setCounter] = useState(null);
  let timer = useRef();

  const startRecording = () => {
    setCounter(3);
    timer = setTimeout(() => updateCounter(3), 1000);
  };

  const updateCounter = (count) => {
    let newCount = count;
    if (count === 1) {
      setCounter('');
      clearInterval(timer);
      newCount = '';
    }
    if (count > 1) {
      setCounter(count - 1);
      newCount = count - 1;
    }
    if (count === null) {
      setCounter(3);
      newCount = 3;
    }
    console.log('new count value', newCount);
    if (newCount === '') {
      return;
    }
    timer = setTimeout(() => updateCounter(newCount), 1000);
  };
  useEffect(() => {
    if (counter === '') {
      onClick();
    }
  }, [counter]);

  if (hide) {
    return null;
  }
  return <Button onClick={startRecording}>{counter}</Button>;
};

export default RecordButton;
