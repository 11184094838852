import React from 'react';
import { useDropzone } from 'react-dropzone';
import ImgIcon from '../../../assets/gallery_icon.svg';
import {
  CoverChange,
  CoverChangeCol,
  DefaultCover,
  GalleryIcon,
  LogoColumn,
  LogoPreview,
  LogoPreviewCol,
  LogoUploadSection,
} from './styles';

const CoverImageUpload = ({ addCoverFile, onChangeAddCoverFile }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.svg'],
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) {
        return;
      }
      const preview = URL.createObjectURL(file);
      const img = new Image();
      img.src = preview;
      onChangeAddCoverFile(
        Object.assign(file, {
          preview,
        })
      );
    },
  });
  return (
    <LogoUploadSection>
      <LogoPreviewCol>
        <LogoColumn {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          {addCoverFile?.preview ? (
            <CoverChangeCol>
              <LogoPreview id="avtar" src={addCoverFile?.preview} />
              <CoverChange>Change</CoverChange>
            </CoverChangeCol>
          ) : (
            <DefaultCover>
              <GalleryIcon src={ImgIcon} />
              Upload image
            </DefaultCover>
          )}
        </LogoColumn>
      </LogoPreviewCol>
    </LogoUploadSection>
  );
};

export default CoverImageUpload;
