import React, { useEffect, useState } from 'react';
import { Loader } from '../Loader';
import useClientDetails from './useClientDetails';
import { BrandLogoIcon } from './styles';

const UserLogo = ({ brandId }: { brandId?: string }) => {
  const [loader, setLoader] = useState(false);
  const { getDetails, clientDetail } = useClientDetails(brandId, setLoader);
  useEffect(() => {
    getDetails();
  }, []);
  const logoStore = localStorage.getItem('userLogo');
  if (!clientDetail?.logo && !logoStore) {
    return null;
  }
  return (
    <>
      {loader && <Loader />}
      {localStorage.getItem('userLogo') ? (
        <BrandLogoIcon src={logoStore} />
      ) : (
        clientDetail?.map((data, id) => {
          return data.logo ? <BrandLogoIcon key={id} src={logoStore} /> : '';
        })
      )}
    </>
  );
};

export default UserLogo;
