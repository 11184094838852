import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCount } from './forces/selector';
import { decrement, increment } from './forces/reducer';

const TestRedux = () => {
  const count = useSelector(getCount);
  const dispatch = useDispatch();
  return (
    <div>
      <p>Count {count}</p>
      <button onClick={() => dispatch(increment())}>Increment</button>
      <button onClick={() => dispatch(decrement())}>Decrement</button>
    </div>
  );
};

export default TestRedux;
