/* eslint-disable @typescript-eslint/naming-convention */
import axios from 'axios';
import { apiURL } from '../config/constants';

const headers = {
  'Content-type': 'application/json',
  Accept: 'application/json',
};
const instance = axios.create({
  baseURL: apiURL,
  headers: headers,
});

const requestHandler = (config) => {
  if (localStorage.accessToken) {
    config.headers['x-access-token'] = localStorage.accessToken;
    config.headers.role = localStorage.role;
    config.headers.userId = localStorage.userId;
  }
  return config;
};

const successHandler = (response) => {
  const res = response && response.data ? response.data : response;
  if (response.headers && response.headers['x-access-token']) {
    res.accessToken = response.headers['x-access-token'];
  }
  return res;
};

const errorHandler = (error) => {
  const errorObj =
    error.response && error.response.data
      ? error.response.data
      : { message: 'Api response failed.' };
  if (errorObj?.code === 'forbiddenError') {
    // Redirect to logout page
  }
  return Promise.reject(errorObj);
};

instance.interceptors.request.use(requestHandler);
instance.interceptors.response.use(successHandler, errorHandler);

export default instance;
