import { useState } from 'react';
import apiClient from '../../network/apiClient';
import { getClientDetails } from '../../config/constants';

const useClientDetails = (getToken, setLoader) => {
  const [clientDetail, setClientDetail] = useState(null);
  const getDetails = async () => {
    try {
      const apiResponse = await apiClient.get(getClientDetails, {
        headers: { Authorization: `Bearer ${getToken}` },
      });
      setClientDetail(apiResponse.data);
      setLoader(false);
    } catch (error) {
      console.log(error, 'ERROR');
    }
  };

  return {
    getDetails,
    clientDetail,
  };
};
export default useClientDetails;
