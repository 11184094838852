import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import ReactPaginate from 'react-paginate';
import Header from '../components/DashboardHeader';
import useBrandVideoDetail from './useBrandVideoDetail';
import DashboardTable from '../components/DashboardTable';
import EditVideoModal from '../components/EditVideoModal';
import { DashboardContainer } from '../components/DashboardContainer';
import { DashboardFooter } from '../components/DashboardFooter/index';
import { Loader } from '../../component/Loader';
import { DashboardBody, editVideoModalStyles } from './styles';

const Dashboard = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editCoverFile, setEditCoverFile] = useState({});
  const [loader, setLoader] = useState(false);
  const getToken = localStorage.getItem('token');
  const { brandVideoDetail, getBrandVideoDetails } = useBrandVideoDetail(
    getToken,
    setLoader
  );
  const [selectedVideo, setSelectedVideo] = useState(null);
  const totalPages = Math.ceil(
    brandVideoDetail?.total / brandVideoDetail?.per_page
  );

  useEffect(() => {
    document.body.classList.add('white-bg');
  }, []);

  useEffect(() => {
    if (!editModalOpen) {
      setLoader(true);
      getBrandVideoDetails();
    }
  }, [editModalOpen]);

  const handleEditModal = useCallback(() => {
    setEditModalOpen((currentValue) => !currentValue);
  }, []);

  const handlePageClick = (event) => {
    getBrandVideoDetails(event.selected + 1);
    setLoader(true);
  };

  const editVideo = (id, data) => {
    setOpenDropdown(id);
    setSelectedVideo(data);
  };
  return (
    <>
      {loader && <Loader />}
      <DashboardBody>
        <Header getBrandVideoDetails={getBrandVideoDetails} />
        <DashboardContainer>
          <DashboardTable
            setOpenDropdown={editVideo}
            openDropdown={openDropdown}
            modalIsOpen={handleEditModal}
            brandVideoDetail={brandVideoDetail}
          />
          {brandVideoDetail?.total >= 8 && (
            <ReactPaginate
              className="pagination-col"
              breakLabel="..."
              nextLabel
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={totalPages}
              previousLabel
              renderOnZeroPageCount={null}
            />
          )}
          <Modal
            isOpen={editModalOpen}
            onRequestClose={handleEditModal}
            style={editVideoModalStyles}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <EditVideoModal
              editCoverFile={editCoverFile}
              setEditCoverFile={setEditCoverFile}
              onRequestClose={handleEditModal}
              data={selectedVideo}
              setLoader={setLoader}
            />
          </Modal>
        </DashboardContainer>
        <DashboardFooter brandVideoDetail={brandVideoDetail} />
      </DashboardBody>
    </>
  );
};

export default Dashboard;
