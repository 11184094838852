import styled from 'styled-components';
import { colors } from '../theme';
import { ReactComponent as PlayIcon } from '../assets/play.svg';
import LoaderIcon from '../assets/loader.svg';

export const ThemeBackground = styled.div`
  background: ${colors.darkGrey};
  padding: 48px 90px;
  @media only screen and (max-width: 767px) {
    padding: 48px 24px;
    height: auto;
  }
`;

export const WelcomeConatiner = styled.div``;

export const WelcomeHeader = styled.div`
  text-align: center;
`;

export const Heading1 = styled.h1`
  color: ${colors.white};
  font-size: 30px;
  font-weight: 800;
  line-height: 36px;
  margin: 32px 0 8px;
`;

export const Paragraph = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.borderColor};
  max-width: 620px;
  margin: 0 auto;
`;

export const VideoPlayer = styled.video`
  max-width: 800px;
`;
export const VideoConatiner = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
  margin-bottom: 32px;
  justify-content: center;
`;

export const VideoThumbnail = styled.div`
  display: flex;
  background: #000;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 200px;
  min-height: 200px;
  overflow: hidden;
  border: 2px solid;
  cursor: pointer;
  border-radius: 8px;
  border-color: #000;
  &[data-selected='true'] {
    border-color: #f00;
  }
`;

export const PlayIconStyled = styled(PlayIcon)`
  position: absolute;
  z-index: 3;
`;

export const VideoCol = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  width: 25%;
  &:last-child {
    margin: 0;
  }
  & video {
    width: 100%;
    border-radius: 8px;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 50%;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const VideoName = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: ${colors.white};
  margin: 8px 0 0;
`;

export const VideoBox = styled.img`
  border-radius: 8px;
`;

export const ArrowIcon = styled.img``;

export const Loader = styled.div`
  background: url(${LoaderIcon}) no-repeat center center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.9);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
  top: 0;
  bottom: 0;
`;

export const BrandVideos = styled.video`
  background: black;
`;
