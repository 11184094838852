import React, { useEffect, useRef, useState } from 'react';
import styles, {
  ApiError,
  ArrowIcon,
  BorderColFour,
  BorderColOne,
  BorderColThree,
  BorderColTwo,
  BorderLineOne,
  BorderLineTwo,
  Button,
  ButtonsContainer,
  customStyles,
  DownloadLink,
  FaceAvtarContainer,
  ModalContent,
  ThemeBackground,
  VideoCaptureContainer,
  WelcomeConatiner,
  WelcomeHeader,
} from './styles';
import Modal from 'react-modal';
import Human from '@vladmandic/human/dist/human.esm';
import RecordRTC from 'recordrtc';
import { detectFaceGestures } from './utils';
import FirstStep from './FirstStep';
import ArrowImgDiable from '../assets/arrow_next_red.svg';
import ArrowImg from '../assets/arrow_next_white.svg';
import useSubmitVideo from './useSubmitVideo';
import UserLogo from '../component/UserLogo';
import ThankYou from './ThankYou';
import { useHistory } from 'react-router-dom';
import {
  FaceAvtarSquare,
  FaceRecordView,
} from './MobileCameraRecording.styles';
import Indication from './components/Indication/Indication';
import loader from '../assets/loading-load.gif';

import Audio from './components/Audio';
import Calculating from './components/Calculating';
import Messages from './components/messages';

import stepWiseData from './helpers/transformData';

const videoMimeType = 'video/webm;codecs=h264';
let rtcRecorder = null;
const videoWidth = 300;
const videoHeight = 300;
const minCloseEyeTime = 2000;
const config = {
  debug: true,
  modelBasePath: 'https://cdn.jsdelivr.net/npm/@vladmandic/human/models',
  face: {
    enabled: true,
    mesh: { enabled: true },
    iris: { enabled: true },
    description: { enabled: false },
    emotion: { enabled: true },
    detector: { rotation: true, return: true },
  },
  body: { enabled: false },
  hand: { enabled: false },
  object: { enabled: false },
};
const enableCanvas = false;
const human = new Human(config);
human.env['perfadd'] = false; // is performance data showing instant or total values

const timestamp = { detect: 0, draw: 0, tensors: 0 }; // holds information used to calculate performance and possible memory leaks
const fps = { detect: 0, draw: 0 }; // holds calculated fps information for both detect and screen refresh
let closeEyesDetections = {
  isClosed: false,
  closedAt: 0,
  totalFrames: 0,
  isCloseError: false,
};
function roundNearest5(x, n = 10) {
  return x < 0 ? x - (x % n) : x - (x % n) + n;
}
const rad2deg = (theta) => Math.round((theta * 180) / Math.PI);

const detectClosedEyes = (gestures, now, setError) => {
  const isClosed =
    gestures
      .map((item) => item.gesture)
      .filter((string) => string.includes('blink')).length > 0;
  if (!isClosed) {
    closeEyesDetections = {
      isClosed: false,
      closedAt: 0,
      totalFrames: 0,
      isCloseError: false,
    };
    return;
  }
  closeEyesDetections.totalFrames++;
  if (!closeEyesDetections.isClosed) {
    closeEyesDetections = {
      ...closeEyesDetections,
      isClosed: true,
      closedAt: now,
    };
    return;
  }
  if (
    !closeEyesDetections.isCloseError &&
    closeEyesDetections.closedAt < now - minCloseEyeTime
  ) {
    closeEyesDetections.isCloseError = true;
    setError({
      closedEyesError: 'Close eyes were detected while recording the video.',
    });
  }
};
const MobileWebCameraRecorder = ({
  isCameraOpen,
  setIsCameraOpen,
  isModelLoaded,
  setIsModelLoaded,
  saveFaceMovements,
  resetVideoMatrics,
  setError,
  isAllStepCompleted,
  videoId,
  isRecordingOn,
  setIsRecordingOn,
  direction,
}) => {
  const [downloadLink, setDownloadLink] = useState('');
  const [rtcRecorderState, setRtcRecorderState] = useState('inactive');
  const [recordingError] = useState('');
  const [isRecordingComplete, setIsRecordingComplete] = useState(false);
  const [errorModal] = useState(false);
  const videoRef = useRef();
  const canvasRef = useRef();
  const [showIndication, setShowIndication] = useState(false);
  const history = useHistory();
  const [currentPosition, setCurrentPosition] = useState('');
  const [play, setPlay] = useState(false);
  const [calculating, setCalculating] = useState(false);
  const step = useRef(0);
  const currentStep = useRef(0);
  const allFrames = useRef(stepWiseData[currentStep.current]); //all[all.order[step.current]].values);
  const [calculationSummary, setCalculationSummary] = useState(false);
  const [scanComplete, setScanComplete] = useState(false);
  const message = 'Position your face within the frame.';
  const {
    isSaved,
    error: videoUploadError,
    uploading,
    saveVideo,
  } = useSubmitVideo();

  const timer = useRef();

  useEffect(() => {
    setShowIndication(false);
    if (!play && isRecordingOn) {
      timer.current = setTimeout(() => {
        setShowIndication(true);
      }, 4000);
    }
    () => clearTimeout(timer.current);
  }, [message, play]);

  useEffect(() => {
    if (!rtcRecorder) {
      return;
    }

    (async () => {
      const rtcState = await rtcRecorder?.getState();
      setRtcRecorderState(rtcState);
    })();
  }, [isRecordingOn]);

  useEffect(() => {
    const loadModels = async () => {
      await human.load();
      human
        .warmup()
        .then(() => {
          setIsModelLoaded(true);
          console.log('warm up ready...');
        })
        .catch((error) => console.error('warpup error: ', error));
    };
    try {
      loadModels();
    } catch (error) {
      console.error({ foundError: error });
    }
  }, []);

  useEffect(() => {
    if (!isAllStepCompleted) {
      return;
    }
    completeRecording();
  }, [isAllStepCompleted]);
  const submitVideo = async () => {
    const blob = rtcRecorder?.getBlob();
    if (!blob) {
      alert('Something went wrong with video recording');
      return;
    }
    const randomNumber = Math.floor(Math.random() * 90000) + 10000;
    const params = {
      type: videoMimeType,
      data: blob,
      id: randomNumber,
      fileName: `${randomNumber}.mp4`,
      location: 'face_video',
    };
    const saved = await saveVideo(params, videoId, '456');
    if (!saved) return;
    history.push('/thankyou');
  };
  useEffect(() => {
    async function startStream() {
      if (!isCameraOpen) return;
      if (!videoRef.current) return;
      console.log('video ref available', videoRef.current);
      if (!rtcRecorder) {
        await initWebCamera();
      }
      const video = videoRef.current;
      try {
        await video.play();
      } catch (error) {
        alert(error);
      }
      if (canvasRef.current) {
        canvasRef.current.width = videoWidth;
        canvasRef.current.height = videoHeight;
      }
      const track = video.srcObject.getVideoTracks()[0];
      const capabilities = track.getCapabilities ? track.getCapabilities() : '';
      const settings = track.getSettings ? track.getSettings() : '';
      const constraints = track.getConstraints ? track.getConstraints() : '';
      console.log('video:', video.videoWidth, video.videoHeight, track.label, {
        stream: video.srcObject,
        track,
        settings,
        constraints,
        capabilities,
      });
      await detectionLoop();
      if (enableCanvas) {
        await drawLoop();
      }
      resetVideoMatrics();
    }
    startStream();
  }, [isCameraOpen, videoRef]);
  const initWebCamera = async () => {
    const options = {
      audio: false,
      video: {
        facingMode: 'user',
        width: { min: 300 },
        height: { min: 300 },
      },
    };
    let stream = null;
    try {
      stream = await navigator.mediaDevices.getUserMedia(options);
      if (!stream) {
        return;
      }
      options.video.height = { min: videoHeight };
      options.video.width = { min: videoWidth };
      stream = await navigator.mediaDevices.getUserMedia(options);
      const video = videoRef.current;
      video.srcObject = stream;
      rtcRecorder = new RecordRTC(stream, {
        type: 'video',
        mimeType: videoMimeType,
      });
    } catch (error) {
      console.log({ error });
      let errorMsg = 'There is something wrong with camera access.';
      if (error?.name === 'OverconstrainedError') {
        errorMsg = 'Video resolution is less than 1280x720.';
      }
      if (error?.name === 'NotAllowedError') {
        errorMsg = 'Please allow to access your camera.';
      }
      setError({
        resolutionError: errorMsg,
      });
      alert(errorMsg);
    }
  };
  const requestRef = React.useRef(0);

  useEffect(() => {
    return () => cancelAnimationFrame(requestRef.current);
  }, []);
  const validateDistance = (distance) => {
    if (distance < 5) {
      // setError({ distanceError: 'Face is too close to the camera.' });
      return;
    }
    if (distance > 80) {
      // setError({ distanceError: 'Face is too far from the camera.' });
      return;
    }
    setError({ distanceError: '' });
  };

  const detectionLoop = async () => {
    if (!videoRef?.current) {
      console.log('not found');
      return;
    }
    console.log('allFrames.current', allFrames.current);
    if (!videoRef.current.paused) {
      const detectedResult = await human.detect(videoRef.current); // actual detection; were not capturing output in a local variable as it can also be reached via human.result
      const tensors = human.tf.memory().numTensors; // check current tensor usage for memory leaks
      timestamp.tensors = tensors;
      if (detectedResult.error) {
        console.error(detectedResult.error);
        return;
      }
      const now = human.now();
      fps.detect = 1000 / (now - timestamp.detect);
      timestamp.detect = now;
      const recorderState = rtcRecorder?.getState();
      if (recorderState === 'recording') {
        const gestures = detectFaceGestures(detectedResult.face);
        saveFaceMovements(gestures);
        if (human.result?.face[0]) {
          let { yaw, pitch } = human.result?.face[0].rotation?.angle || {};
          if (yaw && pitch) {
            yaw = roundNearest5(rad2deg(yaw), 10);
            pitch = roundNearest5(rad2deg(pitch));
            setCurrentPosition(`${yaw} & ${pitch}`);
            const index = allFrames.current?.findIndex(
              (frame) => frame.yaw === yaw && frame.pitch === pitch
            );
            if (index > -1) {
              allFrames.current.splice(index, 1);
            }
          }
        }
        if (allFrames.current?.length < 2) {
          stopForCalculations();
        }
        detectClosedEyes(detectedResult.gesture, now, setError);
      }
      const distance = human.result?.face[0]?.iris;
      if (distance) {
        validateDistance(distance);
      }
    }
    requestRef.current = requestAnimationFrame(detectionLoop); // start new frame immediately
  };

  const drawLoop = async () => {
    if (!videoRef?.current || !canvasRef.current) {
      return;
    }
    const video = videoRef.current;
    if (!video.paused) {
      const ctx = await canvasRef.current.getContext('2d');
      ctx.drawImage(
        videoRef.current,
        0,
        0,
        videoWidth,
        videoHeight,
        0,
        0,
        videoWidth,
        videoHeight
      );
    }
    const now = human.now();
    fps.draw = 1000 / (now - timestamp.draw);
    timestamp.draw = now;
    setTimeout(drawLoop, 10); // use to slow down refresh from max refresh rate to target of 30 fps
  };

  const openWebCamera = async () => {
    setIsCameraOpen(true);
  };

  const startRecording = async () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
    }
    await rtcRecorder?.startRecording();
    timer.current = setTimeout(() => {
      setShowIndication(true);
    }, 4000);
    setIsRecordingOn(true);
  };

  const resumeRecording = async () => {
    await videoRef.current.play();
    await rtcRecorder?.resumeRecording();
    setIsRecordingOn(true);
  };

  const completeRecording = async () => {
    console.log(
      '!videoRef || !videoRef.current',
      !videoRef || !videoRef.current
    );
    if (!videoRef || !videoRef.current) {
      return;
    }
    await rtcRecorder?.stopRecording(async () => {
      const blob = await rtcRecorder?.getBlob();
      if (!blob) {
        return;
      }
      const videoLocal = URL.createObjectURL(blob);
      console.log('videoRef', videoLocal);
      setDownloadLink(videoLocal);
    });
    videoRef.current.pause();
    videoRef.current.srcObject.getTracks()[0].stop();
    setIsRecordingOn(false);
    setIsRecordingComplete(true);
    setRtcRecorderState('inactive');
  };

  const playPauseVideo = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      return;
    }
    videoRef.current.pause();
  };
  if (isSaved) {
    return <ThankYou />;
  }
  const restartVideoRecording = () => {
    setCalculating(false);
    videoRef.current.play();
    rtcRecorder?.resumeRecording();
    // setTimeout(stopForCalculations, 10000);
  };
  const stopForCalculations = () => {
    clearTimeout(timer.current);
    setShowIndication(false);
    videoRef.current.pause();
    rtcRecorder?.pauseRecording();
    console.log('>>>>>>>>>>>>>>>>>>>>>');
    console.log(allFrames.current);
    if (allFrames.current.length >= 4) {
      setCalculationSummary('Redo');
    } else {
      setPlay(true);
      setCalculationSummary('Next Step');
      getNextStep();
    }
    setCalculating(true);
    setTimeout(() => playNextRound(), 1500);
  };

  const getNextStep = () => {
    step.current = step.current + 1;
    currentStep.current = currentStep.current + 1;
    const nexstep = stepWiseData[currentStep.current];
    if (!nexstep) return;
    if (nexstep.length <= 1) {
      getNextStep();
    }
  };

  const finishScanning = () => {
    setCalculating(false);
    setScanComplete(true);
  };
  const isDone = () => {
    return currentStep.current > 14;
  };
  const playNextRound = () => {
    setPlay(false);
    if (isDone()) {
      finishScanning();
      return;
    }
    allFrames.current = stepWiseData[currentStep.current];
    console.log('allFrames.current', allFrames.current);
    restartVideoRecording();
  };
  const startVideoRecording = () => {
    setCalculating(false);
  };
  return (
    <ThemeBackground>
      {/* <Instructions title={all.order[step.current]} /> */}
      <Audio play={play} />
      {/* <div className="positions">
        <div>Current position: {currentPosition}</div>
        <div className="currentPosition">
          Total Frames Left: {allFrames.current.length}
        </div>
        <div>{currentStep.current}</div>
      </div> */}
      {errorModal && (
        <Modal
          isOpen={errorModal}
          onRequestClose={() => window.location.reload()}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <ModalContent>
            You just moved out of the frame. Let's start from the beginning.
            <Button onClick={() => window.location.reload()}>
              Start Again!
            </Button>
          </ModalContent>
        </Modal>
      )}
      {!isCameraOpen && (
        <FirstStep
          brandId={videoId}
          openWebCamera={openWebCamera}
          isLoading={!isModelLoaded}
        />
      )}
      <WelcomeConatiner
        style={isCameraOpen ? styles.videoContainer : { display: 'none' }}
      >
        <WelcomeHeader>
          <UserLogo />
        </WelcomeHeader>
        {!isRecordingComplete ? (
          <>
            {isRecordingOn && <Indication position={currentStep.current} />}
            <FaceAvtarContainer>
              <FaceAvtarSquare id="faceCapture">
                {!isRecordingOn && (
                  <>
                    <BorderColOne>
                      <BorderLineOne lineLeft="3px" lineTop="-3px" />
                      <BorderLineTwo lineLeft="-17px" lineTop="16px" />
                    </BorderColOne>
                    <BorderColTwo>
                      <BorderLineOne lineLeft="1px" lineTop="-3px" />
                      <BorderLineTwo lineLeft="25px" lineTop="16px" />
                    </BorderColTwo>
                    <BorderColThree>
                      <BorderLineOne lineTop="-6px" lineLeft="0" />
                      <BorderLineTwo lineTop="-27px" lineLeft="-17px" />
                    </BorderColThree>
                    <BorderColFour>
                      <BorderLineOne lineTop="2px" lineLeft="2px" />
                      <BorderLineTwo lineLeft="25px" lineTop="-18px" />
                    </BorderColFour>
                  </>
                )}
                <VideoCaptureContainer>
                  {/* <MoveTopRight /> */}
                  {calculating && (
                    <Calculating
                      calculationSummary={calculationSummary}
                      step={currentStep.current}
                      isDone={isDone()}
                      onClick={playNextRound}
                      startRecording={startVideoRecording}
                    />
                  )}
                  <FaceRecordView ref={videoRef} playsInline />
                  {enableCanvas && (
                    <canvas
                      ref={canvasRef}
                      style={styles.canvas}
                      onClick={playPauseVideo}
                    />
                  )}
                </VideoCaptureContainer>
              </FaceAvtarSquare>
            </FaceAvtarContainer>
          </>
        ) : (
          <div>
            <br />
            <br />
            <br />
          </div>
        )}
        <Messages step={currentStep.current} startRecording={isRecordingOn} />
        <ButtonsContainer>
          {rtcRecorderState === 'inactive' && (
            <Button color="green" onClick={startRecording}>
              Start Recording
            </Button>
          )}
          {/* {rtcRecorder && rtcRecorderState === 'recording' && (
            <Button onClick={pauseRecording}>Pause Recording</Button>
          )} */}
          {!isRecordingOn && rtcRecorderState === 'paused' && (
            <Button color="green" onClick={resumeRecording}>
              Resume Recording
            </Button>
          )}
          {/* 
          THESE BUTTONS WILL BE REQUIRED IN THE FUTURE  MAYBE
          BASED ON USER FEEDBACK
          <Button onClick={closeWebcam}>Close Webcam</Button>
          {uploading && (
            <Button>{videoUploadError ? 'Failed' : 'Uploading...'}</Button>
          )} */}
          {recordingError && recordingError}
          {isRecordingComplete && !uploading && (
            <Button disabled={!isAllStepCompleted} onClick={submitVideo}>
              Submit video
              <ArrowIcon src={isAllStepCompleted ? ArrowImg : ArrowImgDiable} />
            </Button>
          )}
          {scanComplete && (
            <>
              <Button onClick={completeRecording}>Finish video</Button>
              {uploading && <img alt="Loading" src={loader} width={64} />}
            </>
          )}
          {videoUploadError && <ApiError>{videoUploadError}</ApiError>}
          {downloadLink && (
            <DownloadLink
              id="download-video"
              download="chose2-video.mp4"
              href={downloadLink}
            >
              Download recorded video
            </DownloadLink>
          )}
        </ButtonsContainer>
      </WelcomeConatiner>
    </ThemeBackground>
  );
};

export default MobileWebCameraRecorder;
