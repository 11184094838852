import React, { useCallback, useState } from 'react';
import Modal from 'react-modal';
import VideoUploadModal from '../../../components/VideoUploadModal';
import UploadNewVideoModal from '../../../components/UploadNewVideoModal';
import { Loader } from '../../../../component/Loader';
import { AddVideoButton, customStyles, editVideoModalStyles } from './styles';
import ButtonIcon from '../../../../assets/plus_icon.svg';

const AddNewVideoButton = ({ getBrandVideoDetails }) => {
  const [videoFile, setVideoFile] = useState({});
  const [addCoverFile, setAddCoverFile] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);

  const closeModal = useCallback(() => {
    setModalOpen(false);
    setVideoFile({});
    setAddCoverFile({});
  }, []);
  return (
    <>
      {loader && <Loader />}
      <AddVideoButton onClick={() => setModalOpen(true)}>
        <img src={ButtonIcon} alt="icon" />
        Add new video
      </AddVideoButton>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={!videoFile.preview ? customStyles : editVideoModalStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        {!videoFile.preview ? (
          <VideoUploadModal
            videoFile={videoFile}
            setVideoFile={setVideoFile}
            onRequestClose={closeModal}
          />
        ) : (
          <UploadNewVideoModal
            onRequestClose={closeModal}
            videoFile={videoFile}
            setVideoFile={setVideoFile}
            addCoverFile={addCoverFile}
            setAddCoverFile={setAddCoverFile}
            setLoader={setLoader}
            getBrandVideoDetails={getBrandVideoDetails}
          />
        )}
      </Modal>
    </>
  );
};

export default AddNewVideoButton;
