import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Error from '../../../component/Error';
import VideoImg from '../../../assets/camera_icon.svg';
import {
  DragText,
  FooterCol,
  FotterContent,
  ModalCancel,
  SelectFileCol,
  VideoFormatText,
  VideoIcon,
  VideoText,
  VideoUploadCol,
} from './styles';
import { colors } from '../../../theme';

const VideoUploadModal = ({ onRequestClose, setVideoFile, videoFile }) => {
  const [fileSizeError, setFileSizeError] = useState('');
  const { getRootProps, acceptedFiles, getInputProps } = useDropzone({
    accept: {
      'video/mp4': ['.mp4', '.mov'],
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) {
        return;
      }
      const preview = URL.createObjectURL(file);
      setVideoFile(
        Object.assign(file, {
          preview,
        })
      );
    },
  });
  return (
    <>
      <VideoUploadCol>
        <SelectFileCol {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <VideoIcon src={VideoImg} />
          <VideoText>
            Upload a video
            <DragText>or drag and drop</DragText>
          </VideoText>
          <VideoFormatText>MOV and MP4 only</VideoFormatText>
        </SelectFileCol>
      </VideoUploadCol>
      <Error touched={true} message={fileSizeError} />
      <FooterCol>
        <ModalCancel onClick={onRequestClose}>Cancel</ModalCancel>
        <ModalCancel
          background={colors.darkGrey}
          color={colors.white}
          border={colors.darkGrey}
          {...getRootProps({ className: 'dropzone' })}
        >
          Select File
        </ModalCancel>
      </FooterCol>
      <FotterContent>
        By submitting your videos to Chose2, you acknowledge that you agree to
        Terms of Service. Please make sure that you do not violate others'
        copyright or privacy rights.
      </FotterContent>
    </>
  );
};

export default VideoUploadModal;
