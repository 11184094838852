import styled from 'styled-components';
import { colors } from '../../theme';

export const ErrorColumn = styled.div`
  color: #7f1d1d;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  color: ${colors.error};
`;
