import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import RecordRTC from 'recordrtc';

const VideoContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  @media only screen and (max-width: 767px) {
    height: 450px;
  }
`;

const VideoElement = styled.video`
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
  transform: scale(-1, 1);
`;
// const VideoRecordedElement = styled.video`
//   width: 100%;
//   object-fit: cover;
//   border-radius: 16px;
//   position: absolute;
//   z-index: 1;
// `;
const RecordedVideo = styled.div`
  width: 400px;
  height: 600px;
  display: flex;
  position: absolute;
  z-index: 1;
  @media only screen and (max-width: 767px) {
    width: 300px;
    height: 450px;
  }
`;

const videoMimeType = 'video/webm;codecs=avc1.4d002a';
const UserStream = ({
  play,
  isReviewing,
  step,
  isDone,
  startTutorial,
  uploadVideos,
}) => {
  const [completedVideos, setCompletedVideos] = useState([]);
  const videoRef = useRef();
  let mediaStream = null;
  let rtcRecorder = useRef();
  const [videoLink, setVideoLink] = useState(null);
  const [videoFile, setVideoFile] = useState(null);

  useEffect(() => {
    if (step) {
      const allVideos = completedVideos;
      allVideos.push(videoFile);
      setCompletedVideos([...allVideos]);
    }
  }, [step]);

  useEffect(async () => {
    console.log('get it');
    const requestedMedia = {
      audio: false,
      video: {
        facingMode: 'user',
        frameRate: { ideal: 25, max: 25 },
        height: { max: 1080, min: 720, ideal: 1080 },
        width: { max: 1920, min: 1280, ideal: 1920 },
      },
    };
    async function enableVideoStream() {
      try {
        mediaStream = await navigator.mediaDevices.getUserMedia(requestedMedia);
        console.log('mediaStream <>', mediaStream);
        rtcRecorder.current = new RecordRTC(mediaStream, {
          type: 'video',
          mimeType: videoMimeType,
        });
        startTutorial();
      } catch (err) {
        console.error('error in rtc init');
        // Handle the error
      }
    }
    await enableVideoStream();
    if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
      videoRef.current.srcObject = mediaStream;
    }
    console.log('in inf', videoRef.current.srcObject);
  }, []);

  const stopRecording = async () => {
    videoRef?.current?.pause();
    videoRef.current.style.filter = 'blur(10px)';
    await rtcRecorder?.current?.stopRecording(async () => {
      const blob = await rtcRecorder?.current?.getBlob();
      if (!blob) {
        return;
      }
      const videoLocal = URL.createObjectURL(blob);
      setVideoLink(videoLocal);
      var file = new File([blob], `video${step}.mp4`);
      setVideoFile(file);
    });
  };

  useEffect(() => {
    if (videoRef && play) {
      videoRef.current.play();
      videoRef.current.style.filter = 'blur(0px)';
      rtcRecorder?.current?.startRecording();
    }
    if (!play && videoRef?.current) {
      stopRecording();
    }
  }, [play]);

  function handleCanPlay() {
    if (play) {
      videoRef.current.play();
    }
  }
  useEffect(() => {
    if (isDone) {
      uploadVideos(completedVideos);
    }
  }, [isDone]);

  return (
    <>
      <RecordedVideo style={{ opacity: isReviewing && videoLink ? 1 : 0 }}>
        <VideoElement src={videoLink} loop autoPlay muted playsInline />
      </RecordedVideo>
      <VideoContainer>
        <VideoElement
          ref={videoRef}
          id="myvideo"
          muted
          playsInline
          onCanPlay={handleCanPlay}
        ></VideoElement>
      </VideoContainer>
    </>
  );
};

export default React.memo(UserStream);
// src="/sample.mp4"
